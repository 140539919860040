<template>
  <div>
    <div class="content-tab border-top-line mb-5">
      <v-container fluid>
        <v-row v-if="aOrders.length > 0">
          <v-col
            class="pa-0"
            cols="12"
            sm="12"
          >
            <div v-if="isResponsive">
              <div
                v-for="(item, index) in aOrders"
                :key="index"
                class="card-content mb-5 pa-3 position-relative"
              >
                <p class="card-txt-title mon-bold">{{ item.sFolioId }}</p>
                <div class="display-flex align-flex-start">
                  <p class="card-txt-inf mon-bold">{{ texts.orders.tableFinished.headers[1] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">{{ item.sCustomerEnterpriseName }}</p>
                </div>
                <div class="display-flex align-flex-start">
                  <p class="card-txt-inf mon-bold">{{ texts.orders.tableFinished.headers[2] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">${{ formatMoney(item.dTotalPrice) }} MXN</p>
                </div>
                <div class="display-flex align-flex-start">
                  <p class="card-txt-inf mon-bold">{{ texts.orders.tableFinished.headers[3] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">
                    {{ 
                      (item.tPurchaseOrderStatusDate !== null)
                      ? item.tPurchaseOrderStatusDate
                      : 'N/A'
                    }}
                  </p>
                </div>
                <div class="display-flex align-flex-start">
                  <p class="card-txt-inf mon-bold">{{ texts.orders.tableFinished.headers[4] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">{{ item.sPurchaseOrderStatusName }}</p>
                </div>
                <div class="display-flex align-flex-start">
                  <p class="card-txt-inf mon-bold">{{ texts.orders.tableFinished.headers[5] }}</p>
                  <v-spacer />
                  <p class="card-txt-res mon-regular">{{ item.sPaid }}</p>
                </div>
                <div class="display-flex align-flex-start">
                  <p class="card-txt-inf mon-bold">{{ texts.orders.tableFinished.headers[6] }}</p>
                  <v-spacer />
                  <p
                    @click="redirectUrl(item.oPurchaseOrderFile.sUrlFile)"
                    class="text-link mon-regular"
                    v-if="item.oPurchaseOrderFile.sUrlFile !== null"
                  >
                    {{(selectLanguage === "sp")? "Facturado":"Download file"}}
                  </p>
                  <p
                    v-else
                    class="mon-regular"
                  >No facturado</p>
                </div>
                <div>
                  <v-btn
                    @click="orderDetail(item.sPurchaseOrderId, item.sPurchaseOrderStatusId)"
                    class="button-primary mon-bold mb-2"
                    width="100%"
                    elevation="0"
                  >
                    {{
                      (selectLanguage == 'sp')
                      ? 'Ver detalle'
                      : 'Show detail'
                    }}
                  </v-btn>
                </div>
              </div>
              <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                <div class="pagination-styles">
                  <v-pagination
                    v-model="iCurrentPage"
                    :length="lengthPage"
                    color="#FFC556"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                  ></v-pagination>
                </div>
              </div>
            </div>
            <div
              v-else
              class="width-100"
            >
              <div class="card-content">
                <v-simple-table
                  height="380px"
                  fixed-header
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th
                          v-for="(item, index) in texts.orders.tableFinished.headers"
                          :key="index"
                          class="text-left text-header-title mon-bold"
                        >
                          {{ item }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in aOrders"
                        :key="index"
                      >
                        <td class="mon-regular">{{ item.sFolioId }}</td>
                        <td class="mon-regular">{{ item.sCustomerEnterpriseName }}</td>
                        <td class="mon-regular">${{ formatMoney(item.dTotalPrice) }} MXN</td>
                        <td class="mon-regular">
                          {{ 
                            (item.tPurchaseOrderStatusDate !== null)
                            ? item.tPurchaseOrderStatusDate
                            : 'N/A'
                           }}
                        </td>
                        <td class="mon-regular">{{ item.sPurchaseOrderStatusName }}</td>
                        <td class="mon-regular">{{ item.sPaid }}</td>
                        <td
                          v-if="item.sPurchaseOrderStatusId == '38b51cdd-95ca-4870-acde-68ed35597adb'"
                          class="mon-regular"
                        >
                          No facturado
                        </td>
                        <td
                          v-else
                          class="mon-regular"
                        >
                          <p
                            @click="redirectUrl(item.oPurchaseOrderFile.sUrlFile)"
                            class="text-link mon-regular"
                            v-if="item.oPurchaseOrderFile.sUrlFile !== null"
                          >
                            {{(selectLanguage === "sp")? "Facturado":"Download file"}}
                          </p>
                        </td>
                        <td class="text-center">
                          <v-btn
                            @click="orderDetail(item.sPurchaseOrderId)"
                            icon
                          >
                            <v-icon
                              color="#283C4D"
                              size="18px"
                            >
                              mdi-eye
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <div class="display-flex align-items-center justify-content-center mt-4 mb-4">
                  <div class="pagination-styles">
                    <v-pagination
                      v-model="iCurrentPage"
                      :length="lengthPage"
                      color="#FFC556"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <div>
              <div
                style="height: 350px !important;"
                class="card-content display-flex align-items-center justify-content-center"
              >
                <div>
                  <!-- <div class="display-flex align-items-center justify-content-center">
                    <img
                      class="empty-image"
                      src="@/assets/images/oops.png"
                      alt=""
                    >
                  </div> -->
                  <p class="empty-text mon-regular">{{ texts.orders.empty }}</p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrdersTabContentThreeLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
    tab: Number,
  },
  data() {
    return {
      //VARIABLES
      iCurrentPage: 1,
      lengthPage: 0,
      aOrders: [],
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getOrdersFinalized();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    redirectUrl: function (url) {
      window.open(url, "blank");
    },
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    formatDate: function (date) {
      var format_date = MOMENT(new Date(date)).format("MMM. DD, YYYY.");
      return format_date;
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    orderDetail: function (id) {
      this.$router.push(`order/finalized/${id}`);
    },
    getOrdersFinalized: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/purchase-orders`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 12,
          iFilter: 3,
          sSearch: this.sSearch,
          tStart: this.startDate,
          tEnd: this.endDate,
          bPaid: this.paymentStatus,
          aOrderStatus: this.orderStatus,
        },
      })
        .then((response) => {
          this.aOrders = response.data.results;

          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.startDate;
    },
    endDate() {
      return this.$store.state.endDate;
    },
    refesh: function () {
      return this.$store.state.refesh;
    },
    paymentStatus: function () {
      return this.$store.state.paymentStatus;
    },
    orderStatus: function () {
      return this.$store.state.orderStatus;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getOrdersFinalized();
      }
    },
    sSearch: lodash.debounce(function (val) {
      this.iCurrentPage = 1;
      this.getOrdersFinalized();
    }, 500),
    endDate: function () {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.getOrdersFinalized();
      }
    },
    startDate: function () {
      this.iCurrentPage = 1;
      this.getOrdersFinalized();
    },
    paymentStatus: function () {
      this.iCurrentPage = 1;
      this.getOrdersFinalized();
    },
    iCurrentPage: function () {
      this.getOrdersFinalized();
    },
    refesh: function () {
      this.getOrdersFinalized();
    },
    tab: function () {
      if (this.tab == 2) {
        this.getOrdersFinalized();
      }
    },
    orderStatus: function () {
      this.iCurrentPage = 1;
      this.getOrdersFinalized();
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-link {
  color: #0971fb;
  text-decoration: underline;
  margin-bottom: 0px;
}

.text-link:hover {
  cursor: pointer;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.display-flex {
  display: flex;
}

.align-items {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.content-tab {
  padding: 20px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}

.card-txt-title {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.card-txt-info {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.card-txt-res {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media (max-width: 600px) {
  .card-txt-title {
    font-size: 16px;
  }
  .card-txt-res {
    font-size: 12px;
    margin-left: 5px;
  }
  .card-txt-inf {
    font-size: 14px;
  }
}
</style>