<template>
  <div class="mt-4">
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <p class="text-title-photography mon-bold mb-0">
              {{ texts.article.lable1 }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="articleNameEs"
            type="text"
            :label="texts.article.dialogEdit.textArticleNameEs"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular"
            :placeholder="texts.article.dialogEdit.textArticleNameEs"
            persistent-placeholder
            clearable
            maxLength="40"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <v-text-field
              v-model="articleNameEn"
              type="text"
              :label="texts.article.dialogEdit.textArticleNameEn"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="texts.article.dialogEdit.textArticleNameEn"
              persistent-placeholder
              clearable
              maxLength="40"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <v-textarea
              v-model="articleDescriptionEs"
              class="global-inputs mon-regular"
              :label="texts.article.dialogEdit.textArticleDescriptionEs"
              :placeholder="texts.article.dialogEdit.textArticleDescriptionEs"
              outlined
              color="#000000"
              dense
              rows="3"
              maxLength="250"
            ></v-textarea>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <v-textarea
              v-model="articleDescriptionEn"
              class="global-inputs mon-regular"
              :label="texts.article.dialogEdit.textArticleDescriptionEn"
              :placeholder="texts.article.dialogEdit.textArticleDescriptionEn"
              outlined
              color="#000000"
              dense
              rows="3"
              maxLength="250"
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <p class="text-title-photography mon-bold mb-0">
              {{ texts.article.lable2 }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-select
              v-model="articleWarehouse"
              :label="texts.article.dialogEdit.textArticleWarehouse"
              :placeholder="texts.article.dialogEdit.textArticleWarehouse"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :items="aWarehouses"
              item-text="oWarehouse.sName"
              item-value="sVendorWarehouseId"
              clearable
            ></v-select>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-select
              v-model="articleCategoryType"
              :label="texts.article.dialogEdit.textArticleCategoryType"
              :placeholder="texts.article.dialogEdit.textArticleCategoryType"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :items="aCatalogsTypes"
              item-text="sName"
              item-value="sCategoryTypeId"
              clearable
            ></v-select>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-select
              v-model="articleCategory"
              :disabled="articleCategoryType == null"
              :label="texts.article.dialogEdit.textArticleCategory"
              :placeholder="texts.article.dialogEdit.textArticleCategory"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :items="aCatalogs"
              item-text="sName"
              item-value="sCategoryId"
              clearable
            ></v-select>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-select
              v-model="articleSubcategory"
              :disabled="articleCategory == null"
              :label="texts.article.dialogEdit.textArticleSubcategory"
              :placeholder="texts.article.dialogEdit.textArticleSubcategory"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :items="aSubcatalogs"
              item-text="sSubcategoryName"
              item-value="sSubcategoryId"
              clearable
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <p class="text-title-photography mon-bold mb-0">
              {{ texts.article.label3 }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-text-field
              v-model="articleLotNumber"
              type="text"
              :label="texts.article.dialogEdit.textArticleLotNumber"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="texts.article.dialogEdit.textArticleLotNumber"
              persistent-placeholder
              clearable
              maxLength="40"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-select
              v-model="articleMeasureUnit"
              :label="texts.article.dialogEdit.textArticleMeasureUnit"
              :placeholder="texts.article.dialogEdit.textArticleMeasureUnit"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :items="aMeasureUnits"
              item-text="sName"
              item-value="sMeasurementUnitId"
              clearable
            ></v-select>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div>
            <v-text-field
              v-model="articlePiecesPerBox"
              type="text"
              :label="textPiecesPerBox()"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="textPiecesPerBox()"
              persistent-placeholder
              clearable
              maxLength="10"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div>
            <v-text-field
              v-model="articleWeightPerBox"
              type="text"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :label="textWeightPerBox()"
              :placeholder="textWeightPerBox()"
              persistent-placeholder
              suffix="LB"
              clearable
              maxLength="5"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <div>
            <v-text-field
              v-model="articleBoxesPerPallet"
              type="text"
              :label="textBoxesPerPallet()"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="textBoxesPerPallet()"
              persistent-placeholder
              clearable
              maxLength="10"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <p class="text-title-photography mon-bold mb-0">
              {{ texts.article.label4 }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-text-field
              v-model="articleTemperatureWarehouse"
              type="text"
              :label="texts.article.dialogEdit.textArticleTemperatureWarehouse"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="texts.article.dialogEdit.textArticleTemperatureWarehouse"
              persistent-placeholder
              clearable
              suffix="°F"
              maxLength="3"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-text-field
              v-model="articleTemperatureTraslado"
              type="text"
              :label="texts.article.dialogEdit.textArticleTemperatureTraslado"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="
                texts.article.dialogEdit.textArticleTemperatureTraslado
              "
              persistent-placeholder
              clearable
              suffix="°F"
              maxLength="3"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <p class="text-title-photography mon-bold mb-0">
              {{ texts.article.label5 }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <div style="position: relative">
            <!-- <p
              style="background-color: transparent; margin-top: -1px;"
              class="label-input-curreny mon-regular"
            >{{ texts.article.dialogEdit.textArticleDisponibility }}</p> -->
            <v-menu
              ref="bMenuFinal"
              v-model="bMenuFinal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  color="#000000"
                  dense
                  class="global-inputs mon-regular"
                  readonly
                  v-model="articleDisponibilityTextField"
                  clearable
                  hide-details
                  append-icon="mdi-calendar"
                  @click:clear="articleDisponibility = null"
                  :label="texts.article.dialogEdit.textArticleDisponibility"
                  :placeholder="
                    texts.article.dialogEdit.textArticleDisponibility
                  "
                  persistent-hint
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#C41F79"
                v-model="articleDisponibility"
                @change="changeFormatArticleDisponibility(articleDisponibility)"
                no-title
                @input="bMenuFinal = false"
                :min="actualDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div style="position: relative">
            <v-menu
              ref="menu1"
              v-model="time1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="articleDisponibilityHour"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="articleDisponibilityHour"
                  :label="texts.article.txtLabelHoraEstimada"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  color="#000000"
                  class="global-inputs mon-regular"
                  :placeholder="texts.article.txtLabelHoraEstimada"
                  persistent-placeholder
                  suffix="24 Hrs"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="time1"
                v-model="articleDisponibilityHour"
                full-width
                @click:minute="$refs.menu1.save(articleDisponibilityHour)"
                format="24hr"
                header-color="#151d27"
                color="#151d27"
              ></v-time-picker>
            </v-menu>
            <!-- <p
              style="background-color: transparent; margin-top: -1px;"
              class="label-input-curreny mon-regular"
            >{{ texts.article.txtLabelHoraEstimada }}</p>
            <v-text-field
              v-model="articleDisponibilityHour"
              placeholder="00:00"
              type="time"
              min="1:00"
              max="24:00"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              persistent-placeholder
              suffix="24 Hrs"
              maxLength="5"
              @keypress="soloNumbers"
            ></v-text-field> -->
          </div>
        </v-col>
        <!-- <v-col
          cols="12"
          md="6"
        >
          <div>
            <v-text-field
              v-model="articlePreparationTime"
              type="text"
              :label="texts.article.dialogEdit.textArticlePreparationTime"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="texts.article.dialogEdit.textArticlePreparationTime"
              persistent-placeholder
              clearable
              prepend-inner-icon="mdi-clock"
              suffix="Hrs"
              maxLength="2"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div>
            <p class="text-title-photography mon-bold mb-0">
              {{ texts.article.label6 }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-text-field
              v-model="articleBoxQuantity"
              type="text"
              :label="texts.article.dialogEdit.textArticleBoxQuantity"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="texts.article.dialogEdit.textArticleBoxQuantity"
              persistent-placeholder
              clearable
              maxLength="10"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col> -->
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-text-field
              v-model="articleMinAmount"
              type="text"
              :label="textArticleMinAmount()"
              outlined
              color="#000000"
              dense
              class="global-inputs mon-regular"
              :placeholder="textArticleMinAmount()"
              persistent-placeholder
              clearable
              maxLength="10"
              @keypress="soloNumbers"
            ></v-text-field>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div style="position: relative">
            <p
              style="background-color: transparent"
              class="label-input-curreny mon-regular"
            >
              {{ textArticlePrice() }}
            </p>
            <currency-input
              class="input-currency mon-regular"
              maxlength="12"
              v-model="articlePrice"
              :distraction-free="false"
              currency="MXN"
              @keyup="changearticleprice"
              locale="es-MX"
            />
          </div>
        </v-col>
      </v-row>
      <!-- gallery and buttons -->
      <v-row>
        <v-col>
          <div class="mt-4">
            <p class="text-title-photography mon-bold">
              {{ texts.article.dialogEdit.textPhographyTitle }}
            </p>
            <p class="text-subtitle-photography mon-regular">
              {{ texts.article.dialogEdit.textPhographySubtitle }}
            </p>
            <div class="scroll-horizontal mt-9">
              <div class="display-flex align-items-center">
                <!-- upload 1 -->
                <div>
                  <div
                    v-if="imageFile1_data !== null"
                    class="button-add-document mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages1(imageFile1_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile1_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload1.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile1_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload1"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse1"
                    />
                  </v-btn>
                </div>
                <!-- upload 2 -->
                <div>
                  <div
                    v-if="imageFile2_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages2(imageFile2_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile2_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload2.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile2_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload2"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse2"
                    />
                  </v-btn>
                </div>
                <!-- upload 3 -->
                <div>
                  <div
                    v-if="imageFile3_data !== null"
                    class="button-add-document mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages3(imageFile3_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile3_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload3.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile3_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload3"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse3"
                    />
                  </v-btn>
                </div>
                <!-- upload 4 -->
                <div>
                  <div
                    v-if="imageFile4_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages4(imageFile4_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile4_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload4.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile4_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload4"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse4"
                    />
                  </v-btn>
                </div>
                <!-- upload 5 -->
                <div>
                  <div
                    v-if="imageFile5_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages5(imageFile5_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile5_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload5.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile5_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload5"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse5"
                    />
                  </v-btn>
                </div>
                <!-- upload 6 -->
                <div>
                  <div
                    v-if="imageFile6_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages6(imageFile6_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile6_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload6.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile6_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload6"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse6"
                    />
                  </v-btn>
                </div>
                <!-- upload 7 -->
                <div>
                  <div
                    v-if="imageFile7_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages7(imageFile7_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile7_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload7.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile7_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload7"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse7"
                    />
                  </v-btn>
                </div>
                <!-- upload 8 -->
                <div>
                  <div
                    v-if="imageFile8_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages8(imageFile8_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile8_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload8.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile8_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload8"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse8"
                    />
                  </v-btn>
                </div>
                <!-- upload 9 -->
                <div>
                  <div
                    v-if="imageFile9_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages9(imageFile9_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile9_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload9.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile9_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload9"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse9"
                    />
                  </v-btn>
                </div>
                <!-- upload 10 -->
                <div>
                  <div
                    v-if="imageFile10_data !== null"
                    class="button-add-document mon-bold mr-5"
                  >
                    <div class="content-image-articles">
                      <v-btn
                        rounded
                        class="remove-image-icon"
                        @click="removeImages10(imageFile10_data_key)"
                      >
                        <v-icon
                          color="#F50017"
                          size="22px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                      <img
                        :src="imageFile10_data"
                        alt=""
                      />
                    </div>
                  </div>
                  <v-btn
                    v-else
                    @click="$refs.inputUpload10.click()"
                    class="button-add-document mon-bold mr-5"
                    elevation="0"
                  >
                    <div :style="
                        imageFile10_data !== null
                          ? { display: 'none' }
                          : { display: 'block' }
                      ">
                      <v-icon
                        color="#B2B2B2"
                        size="60px"
                      >
                        mdi-image-plus
                      </v-icon>
                      <p
                        class="text-button-document mon-semibold mt-2"
                        v-html="texts.article.dialogEdit.textLabelImage"
                      />
                    </div>
                    <input
                      ref="inputUpload10"
                      style="display: none"
                      type="file"
                      size="60"
                      accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                      @change="uploadFilesWarehouse10"
                    />
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="border-bottom" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="isResponsive">
            <v-btn
              :loading="bLoading"
              :disabled="!validateForm"
              elevation="0"
              @click="saveArticle"
              class="button-primary mon-bold"
              style="width: 100%;"
            >
              {{ texts.article.dialogEdit.textButtonSave }}
            </v-btn>
            <v-btn
              elevation="0"
              @click="clearForm"
              class="button-secondary mon-regular mt-5"
              style="width: 100%;"
            >
              {{ texts.article.dialogEdit.textButtonCancel }}
            </v-btn>
          </div>
          <div
            v-else
            class="display-flex align-items-center justify-content-flex-end"
          >
            <v-btn
              elevation="0"
              @click="clearForm"
              class="button-secondary mon-regular mr-1"
            >
              {{ texts.article.dialogEdit.textButtonCancel }}
            </v-btn>
            <v-btn
              :loading="bLoading"
              :disabled="!validateForm"
              elevation="0"
              @click="saveArticle"
              class="button-primary mon-bold ml-1"
            >
              {{ texts.article.dialogEdit.textButtonSave }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
  </div>
</template>

<script>
export default {
  name: "ArticleDetailDialogEditLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    //VARIABLES
    return {
      dataImgs: [],
      screenWidth: 0,
      articleNameEs: "",
      articleNameEn: "",
      articleCategoryType: null,
      articleCategory: null,
      articleSubcategory: null,
      articleLotNumber: "",
      articleDescriptionEs: "",
      articleDescriptionEn: "",
      articlePrice: 0,
      articleWarehouse: "",
      articleDisponibility: null,
      articleDisponibilityTextField: null,
      articleDisponibilityHour: "",
      // articlePreparationTime: "",
      articleTemperatureWarehouse: "",
      articleTemperatureTraslado: "",
      articleMinAmount: "",
      articleMeasureUnit: "42eb37cc-19c4-4316-923f-a892c0cb5681",
      articleBoxesPerPallet: "",
      articlePiecesPerBox: "",
      articleWeightPerBox: "",
      aMeasureUnits: [],
      aCatalogsTypes: [],
      aCatalogs: [],
      aSubcatalogs: [],
      aWarehouses: [],
      bMenuFinal: false,
      // --- images --- //
      imagesDeletes: [],
      imageFile1_data: null,
      imageFile2_data: null,
      imageFile3_data: null,
      imageFile4_data: null,
      imageFile5_data: null,
      imageFile6_data: null,
      imageFile7_data: null,
      imageFile8_data: null,
      imageFile9_data: null,
      imageFile10_data: null,
      imageFile1: null,
      imageFile2: null,
      imageFile3: null,
      imageFile4: null,
      imageFile5: null,
      imageFile6: null,
      imageFile7: null,
      imageFile8: null,
      imageFile9: null,
      imageFile10: null,
      imageName1: "",
      imageName2: "",
      imageName3: "",
      imageName4: "",
      imageName5: "",
      imageName6: "",
      imageName7: "",
      imageName8: "",
      imageName9: "",
      imageName10: "",
      imageFile1_data_key: "",
      imageFile2_data_key: "",
      imageFile3_data_key: "",
      imageFile4_data_key: "",
      imageFile5_data_key: "",
      imageFile6_data_key: "",
      imageFile7_data_key: "",
      imageFile8_data_key: "",
      imageFile9_data_key: "",
      imageFile10_data_key: "",
      // --- spinner --- //
      bLoading: false,
      time1: null,
      actualDate: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getArticles();
    this.getActualDate();
    // this.getCatalogsTypes();
    // this.getWarehouses();
    // this.getMeasureUnits();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getActualDate: function () {
      let hoy = new Date();
      let dia_en_milisegundo = 24 * 60 * 60 * 1000;
      let ayer = new Date(hoy.getTime() - dia_en_milisegundo);

      this.actualDate = ayer.toISOString().substring(0, 10);
    },
    getArticles: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/products/${this.$route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {
            // bCustomer: true,
            aImagesSizes: "md,lg",
          },
        }
      )
        .then((response) => {
          let arr = response.data.results;

          // // ----------- article information ------------- //
          this.articleCategoryType = arr.sCategoryTypeId;
          this.articleCategory = arr.sCategoryId;
          this.articleSubcategory = arr.sSubcategoryId;
          this.articleTemperatureWarehouse = arr.dStorageTemperature;
          this.articleTemperatureTraslado = arr.dTransportationTemperature;
          // this.articlePreparationTime = arr.iPreparationTime;
          this.articlePrice = Number(arr.dUnitPrice);
          this.articleNameEs = arr.aTranslations.find((res) => {
            return res.sLang == "sp";
          }).sName;
          this.articleNameEn = arr.aTranslations.find((res) => {
            return res.sLang == "en";
          }).sName;
          this.articleDescriptionEs = arr.aTranslations.find((res) => {
            return res.sLang == "sp";
          }).sDescription;
          this.articleDescriptionEn = arr.aTranslations.find((res) => {
            return res.sLang == "en";
          }).sDescription;
          this.articleLotNumber = arr.sLotId;
          this.articleMeasureUnit = arr.oMeasurementUnit.sMeasurementUnitId;
          this.getWarehouses();
          this.articleWarehouse = arr.sVendorWarehouseId;
          this.articleMinAmount = arr.dMinAmountAllowed;

          // Start: ormate date with moment js
          var new_date = MOMENT(arr.tProductAvailableTime, "DD-MM-YYYY");

          var format_date = MOMENT(new_date).format("YYYY-MM-DD");
          this.articleDisponibility = format_date;
          // this.articleDisponibilityTextField =
          this.changeFormatArticleDisponibility(format_date);
          var formate_hour = arr.tProductAvailableTime.split(" ")[1];
          var hours = MOMENT(formate_hour, "h:mm:ss A").format("HH:mm");
          this.articleDisponibilityHour = hours;
          // End: ormate date with moment js

          this.articleBoxesPerPallet =
            arr.oMeasurementUnit.oData.dBoxesPerPallet;
          this.articlePiecesPerBox = arr.oMeasurementUnit.oData.dPiecesPerBox;
          this.articleWeightPerBox = arr.oMeasurementUnit.oData.dWeightPerBox;

          // ----------- images ------------- //
          let images = arr.aImages;

          this.imageFile1_data =
            images[0] == undefined ? null : images[0].oImages.md;
          this.imageFile2_data =
            images[1] == undefined ? null : images[1].oImages.md;
          this.imageFile3_data =
            images[2] == undefined ? null : images[2].oImages.md;
          this.imageFile4_data =
            images[3] == undefined ? null : images[3].oImages.md;
          this.imageFile5_data =
            images[4] == undefined ? null : images[4].oImages.md;
          this.imageFile6_data =
            images[5] == undefined ? null : images[5].oImages.md;
          this.imageFile7_data =
            images[6] == undefined ? null : images[6].oImages.md;
          this.imageFile8_data =
            images[7] == undefined ? null : images[7].oImages.md;
          this.imageFile9_data =
            images[8] == undefined ? null : images[8].oImages.md;
          this.imageFile10_data =
            images[9] == undefined ? null : images[9].oImages.md;

          this.imageFile1_data_key =
            images[0] == undefined ? null : images[0].sImageKey;
          this.imageFile2_data_key =
            images[1] == undefined ? null : images[1].sImageKey;
          this.imageFile3_data_key =
            images[2] == undefined ? null : images[2].sImageKey;
          this.imageFile4_data_key =
            images[3] == undefined ? null : images[3].sImageKey;
          this.imageFile5_data_key =
            images[4] == undefined ? null : images[4].sImageKey;
          this.imageFile6_data_key =
            images[5] == undefined ? null : images[5].sImageKey;
          this.imageFile7_data_key =
            images[6] == undefined ? null : images[6].sImageKey;
          this.imageFile8_data_key =
            images[7] == undefined ? null : images[7].sImageKey;
          this.imageFile9_data_key =
            images[8] == undefined ? null : images[8].sImageKey;
          this.imageFile10_data_key =
            images[9] == undefined ? null : images[9].sImageKey;

          this.getCatalogsTypes();
          this.getMeasureUnits();
          this.getCatalogs();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 &&
          evt.which != 0 &&
          evt.which < 48 &&
          evt.which == 109) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    removeImages1: function (e) {
      this.imageFile1_data = null;
      this.imageFile1_data_key = "";
      this.imageFile1 = null;
      this.imagesDeletes.push(e);
    },
    removeImages2: function (e) {
      this.imageFile2_data = null;
      this.imageFile2_data_key = "";
      this.imageFile2 = null;
      this.imagesDeletes.push(e);
    },
    removeImages3: function (e) {
      this.imageFile3_data = null;
      this.imageFile3_data_key = "";
      this.imageFile3 = null;
      this.imagesDeletes.push(e);
    },
    removeImages4: function (e) {
      this.imageFile4_data = null;
      this.imageFile4_data_key = "";
      this.imageFile4 = null;
      this.imagesDeletes.push(e);
    },
    removeImages5: function (e) {
      this.imageFile5_data = null;
      this.imageFile5_data_key = "";
      this.imageFile5 = null;
      this.imagesDeletes.push(e);
    },
    removeImages6: function (e) {
      this.imageFile6_data = null;
      this.imageFile6_data_key = "";
      this.imageFile6 = null;
      this.imagesDeletes.push(e);
    },
    removeImages7: function (e) {
      this.imageFile7_data = null;
      this.imageFile7_data_key = "";
      this.imageFile7 = null;
      this.imagesDeletes.push(e);
    },
    removeImages8: function (e) {
      this.imageFile8_data = null;
      this.imageFile8_data_key = "";
      this.imageFile8 = null;
      this.imagesDeletes.push(e);
    },
    removeImages9: function (e) {
      this.imageFile9_data = null;
      this.imageFile9_data_key = "";
      this.imageFile9 = null;
      this.imagesDeletes.push(e);
    },
    removeImages10: function (e) {
      this.imageFile10_data = null;
      this.imageFile10_data_key = "";
      this.imageFile10 = null;
      this.imagesDeletes.push(e);
    },
    saveArticle: function () {
      this.bLoading = true;
      let join_date =
        this.articleDisponibility + " " + this.articleDisponibilityHour;
      // join_date.replace(/-/g, "/");

      const payload = {
        sSubcategoryId: this.articleSubcategory,
        sVendorWarehouseId: this.articleWarehouse,
        dMinAmountAllowed: this.articleMinAmount,
        dUnitPrice: this.articlePrice,
        sMeasurementUnitId: this.articleMeasureUnit,
        tProductAvailableTime: join_date,
        // iPreparationTime: this.articlePreparationTime,
        sLotId: this.articleLotNumber,
        dTransportationTemperature: this.articleTemperatureTraslado,
        dStorageTemperature: this.articleTemperatureWarehouse,
        dBoxesPerPallet: this.articleBoxesPerPallet,
        dPiecesPerBox: this.articlePiecesPerBox,
        dWeightPerBox: this.articleWeightPerBox,
        aTranslations: [
          {
            sLang: "en",
            sName: this.articleNameEn,
            sDescription: this.articleDescriptionEn,
          },
          {
            sLang: "sp",
            sName: this.articleNameEs,
            sDescription: this.articleDescriptionEs,
          },
        ],
        aImagesKey: this.imagesDeletes,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/products/${this.$route.params.id}`,
        payload,
        config
      )
        .then((response) => {
          this.uploadImages(response.data.results.sProductId);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    uploadImages: function (id) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      let form = new FormData();
      let arr = [
        this.imageFile1,
        this.imageFile2,
        this.imageFile3,
        this.imageFile4,
        this.imageFile5,
        this.imageFile6,
        this.imageFile7,
        this.imageFile8,
        this.imageFile9,
        this.imageFile10,
      ];
      arr.forEach((element) => {
        if (element) {
          form.append("image", element);
        }
      });


      if (!form.entries().next().done) {
        DB.patch(
          `${URI}/api/v1/${this.selectLanguage}/products/${id}/storage`,
          form,
          config
        )
          .then((response) => {
            this.bLoading = false;
            this.mixSuccess(response.data.message);
            this.clearForm();
            this.$store.commit("refresher", true);
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.bLoading = false;
        this.mixSuccess(this.texts.article.messageEdit);
        this.clearForm();
        this.$store.commit("refresher", true);
      }
    },
    getMeasureUnits: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(`${URI}/api/v1/${this.selectLanguage}/measurement_units`, config)
        .then((response) => {
          this.aMeasureUnits = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getWarehouses: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
        config
      )
        .then((response) => {
          this.aWarehouses = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getCatalogsTypes: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/types`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.aCatalogsTypes = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectCategory: function (id) {
      this.getCatalogs(id);
    },
    getCatalogs: function (id) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/categories?sCategoryTypeId=${this.articleCategoryType}`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.aCatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectSubcategory: function (id) {
      this.getSubcatalogs(id);
    },
    getSubcatalogs: function (id) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/subcategories?sCategoryId=${this.articleCategory}`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.aSubcatalogs = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 80;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    clearForm: function () {
      this.imageFile1_data = null;
      this.imageFile2_data = null;
      this.imageFile3_data = null;
      this.imageFile4_data = null;
      this.imageFile5_data = null;
      this.imageFile6_data = null;
      this.imageFile7_data = null;
      this.imageFile8_data = null;
      this.imageFile9_data = null;
      this.imageFile10_data = null;
      this.imageFile1 = null;
      this.imageFile2 = null;
      this.imageFile3 = null;
      this.imageFile4 = null;
      this.imageFile5 = null;
      this.imageFile6 = null;
      this.imageFile7 = null;
      this.imageFile8 = null;
      this.imageFile9 = null;
      this.imageFile10 = null;
      this.imageName1 = "";
      this.imageName2 = "";
      this.imageName3 = "";
      this.imageName4 = "";
      this.imageName5 = "";
      this.imageName6 = "";
      this.imageName7 = "";
      this.imageName8 = "";
      this.imageName9 = "";
      this.imageName10 = "";
      this.$router.push({
        name: "ArticleDetail",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    uploadFilesWarehouse1: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile1_data = fr.result;
          this.imageFile1 = files[0];
          this.imageName1 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse2: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile2_data = fr.result;
          this.imageFile2 = files[0];
          this.imageName2 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse3: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile3_data = fr.result;
          this.imageFile3 = files[0];
          this.imageName3 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse4: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile4_data = fr.result;
          this.imageFile4 = files[0];
          this.imageName4 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse5: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile5_data = fr.result;
          this.imageFile5 = files[0];
          this.imageName5 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse6: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile6_data = fr.result;
          this.imageFile6 = files[0];
          this.imageName6 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse7: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile7_data = fr.result;
          this.imageFile7 = files[0];
          this.imageName7 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse8: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile8_data = fr.result;
          this.imageFile8 = files[0];
          this.imageName8 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse9: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile9_data = fr.result;
          this.imageFile9 = files[0];
          this.imageName9 = files[0].name;
        });
      }
    },
    uploadFilesWarehouse10: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageFile10_data = fr.result;
          this.imageFile10 = files[0];
          this.imageName10 = files[0].name;
        });
      }
    },
    // textos
    capitalize: function (str) {
      let lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice("1");
    },
    textWeightPerBox: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Peso aproximado por " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        } else {
          text =
            "Aproximate weight per " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        }
      text = this.capitalize(text);
      return text;
    },
    textPiecesPerBox: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Piezas por " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        } else {
          text =
            "Aprox pieces per " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        }
      text = this.capitalize(text);
      return text;
    },
    textArticlePrice: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Precio por " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName;
        } else {
          text =
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName + " price";
        }
      text = this.capitalize(text);
      return text;
    },
    textArticleMinAmount: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            "Mínimo de " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName +
            " disponibles para la venta";
        } else {
          text =
            "Minimum amount of " +
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName +
            " available for sale";
        }
      text = this.capitalize(text);
      return text;
    },
    textBoxesPerPallet: function () {
      let text = "";
      if (this.aMeasureUnits.length > 0)
        if (this.selectLanguage == "sp") {
          text =
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName + " por pallet";
        } else {
          text =
            this.aMeasureUnits.find((res) => {
              return res.sMeasurementUnitId == this.articleMeasureUnit;
            }).sName + " per pallet";
        }
      return text;
    },
    changearticleprice() {
      if (this.articlePrice === null) {
        this.articlePrice = 0;
      }
    },
    changeFormatArticleDisponibility(dateArticle) {
      let aDate = dateArticle.split("-");

      let day = aDate[2];
      let month = aDate[1];
      let year = aDate[0];
      let date = month + "/" + day + "/" + year;
      this.articleDisponibilityTextField = date;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.articleNameEs !== "" &&
        this.articleNameEn !== "" &&
        this.articleCategoryType !== "" &&
        this.articleCategory !== "" &&
        this.articleSubcategory !== "" &&
        this.articleLotNumber !== "" &&
        this.articleDescriptionEs !== "" &&
        this.articleDescriptionEn !== "" &&
        this.articlePrice !== "" &&
        this.articleWarehouse !== "" &&
        this.articleDisponibility !== "" &&
        // this.articlePreparationTime !== "" &&
        this.articleTemperatureWarehouse !== "" &&
        this.articleTemperatureTraslado !== "" &&
        this.articleMinAmount !== "" &&
        this.articleMeasureUnit !== "" &&
        this.articleBoxesPerPallet !== "" &&
        this.articlePiecesPerBox !== "" &&
        this.articleWeightPerBox !== "" &&
        this.articleNameEs !== null &&
        this.articleNameEn !== null &&
        this.articleCategoryType !== null &&
        this.articleCategory !== null &&
        this.articleSubcategory !== null &&
        this.articleLotNumber !== null &&
        this.articleDescriptionEs !== null &&
        this.articleDescriptionEn !== null &&
        this.articlePrice !== null &&
        this.articleWarehouse !== null &&
        this.articleDisponibility !== null &&
        // this.articlePreparationTime !== null &&
        this.articleTemperatureWarehouse !== null &&
        this.articleTemperatureTraslado !== null &&
        this.articleMinAmount !== null &&
        this.articleMeasureUnit !== null &&
        this.articleBoxesPerPallet !== null &&
        this.articlePiecesPerBox !== null &&
        this.articleWeightPerBox !== null
      );
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getCatalogsTypes();
        this.getWarehouses();
      }
    },
    articleCategoryType() {
      if (this.articleCategoryType == null) {
        this.articleCategory = null;
        this.aCatalogs = [];
        this.articleSubcategory = null;
        this.aSubcatalogs = [];
      } else {
        this.selectCategory(this.articleCategoryType);
      }
    },
    articleCategory() {
      if (this.articleCategory == null) {
        this.articleSubcategory = null;
        this.aSubcatalogs = [];
      } else {
        this.selectSubcategory(this.articleCategory);
      }
    },
    // articlePrice(){
    //   if (this.articlePrice === null) {
    //     this.articlePrice = 0
    //   }
    // }
  },
};
</script>

<style scoped>
.remove-image-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 100%;
  min-width: fit-content !important;
  height: fit-content !important;
  padding: 7px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff99 !important;
  z-index: 3;
}

.border-bottom {
  border-bottom: 1px solid #cecece;
}

.scroll-horizontal {
  overflow-x: auto;
  overflow-y: hidden;
}

.button-add-document {
  width: 180px !important;
  height: 160px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #707070;
  border-radius: 20px;
  opacity: 1;
  z-index: 1;
  position: relative;
}

.content-image-articles {
  width: 180px;
  height: 160px;
  border-radius: 20px;
}

.content-image-articles img {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

@supports (object-fit: cover) {
  .content-image-articles img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.text-button-document {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 1;
  text-transform: initial;
}

.text-title-photography {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #304458;
  opacity: 1;
  margin-bottom: 0px;
}

.text-subtitle-photography {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title {
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
  position: relative;
}

.icon-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>