<template>
  <v-container v-if="isResponsive">
    <v-row>
      <v-col class="pb-0">
        <div>
          <div class="content-titles-and-links display-flex align-items-center">
            <p class="text-title mon-bold">
              {{ texts.home.article.textTitle }} 🍎
            </p>
            <v-spacer></v-spacer>
            <div class="display-flex align-items-center">
              <p
                @click="redirectArticles"
                class="text-link cursor-pointer text-underline mon-medium"
              >
                {{ texts.home.article.textAllArticles }}
              </p>
              <v-icon
                color="#0071FF"
                size="18px"
              > mdi-chevron-right </v-icon>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="aArticles.length > 0">
      <v-col cols="12">
        <div class="scrolled-lateral">
          <div
            v-for="(item, index) in aArticles"
            :key="index"
            class="card-container mr-5"
            style="min-width: 300px"
          >
            <div class="content-image mb-2">
              <img
                v-if="item.aImages.length > 0"
                :src="item.aImages[0].oImages.md"
              />
              <img
                v-else
                src="@/assets/images/placeholder-image.jpeg"
              />
            </div>
            <div
              class="content-div-text"
              :style="{ height: `${restantHeightName}px` }"
            >
              <p class="text-article-name mon-semibold">
                {{ item.sName }}
              </p>
            </div>

            <div
              class="display-flex mt-2"
              style="align-items: flex-start"
              :style="{ height: `${resizeDivLocation}px` }"
            >
              <v-icon
                color="#9AA6B1"
                size="14px"
              > mdi-map-marker </v-icon>
              <p class="text-article-address mon-bold">
                {{ item.sLocationWarehouse }}
              </p>
            </div>
            <div class="display-flex align-items-center mt-2 mb-2">
              <div class="display-flex align-items-center mt-2 mb-2">
                <v-rating
                  :value="item.dRating"
                  length="5"
                  color="#FFC92A"
                  background-color="#C6C6C8"
                  dense
                  small
                  readonly
                ></v-rating>
                <p
                  :style="item.dRating == 0 ? { color: '#C6C6C8' } : {}"
                  class="text-calif-num mon-bold ml-1 mb-n1"
                >
                  {{ item.dRating > 5 ? 5 : item.dRating }}
                </p>
              </div>
              <v-spacer></v-spacer>
            </div>
            <div
              class="content-div-text"
              :style="{ height: `${restantHeightPrice}px` }"
            >
              <p class="text-article-price mon-bold">
                ${{ formatMoney(item.dUnitPrice) }} /
                {{ item.oMeasurementUnit.sName }}
              </p>
            </div>
            <div class="mt-2">
              <v-btn
                @click="articleDetail(item.sProductId)"
                class="button-view mon-bold"
                elevation="0"
              >
                {{ texts.home.article.textButtonView }}
                <v-icon
                  color="#283C4D"
                  size="14px"
                  class="ml-1"
                >
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <div class="
            empty-content
            display-flex
            align-items-center
            justify-content-center
          ">
          <div>
            <!-- <div class="display-flex align-items-center justify-content-center">
              <img
                class="empty-image"
                src="@/assets/images/oops.png"
                alt=""
              >
            </div> -->
            <p class="empty-text mon-regular">
              {{ texts.home.article.empty.text }}
            </p>
            <div class="
                display-flex
                align-items-center
                justify-content-center
                mt-3
              ">
              <v-btn
                @click="validation"
                class="button-primary mon-medium"
                elevation="0"
              >
                {{ texts.home.article.empty.link }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container
    v-else
    fluid
  >
    <v-row>
      <v-col class="pb-0">
        <div>
          <div class="content-titles-and-links display-flex align-items-center">
            <p class="text-title mon-bold">
              {{ texts.home.article.textTitle }} 🍎
            </p>
            <v-spacer></v-spacer>
            <div class="display-flex align-items-center">
              <p
                @click="redirectArticles"
                class="text-link cursor-pointer text-underline mon-medium"
              >
                {{ texts.home.article.textAllArticles }}
              </p>
              <v-icon
                color="#0071FF"
                size="18px"
              > mdi-chevron-right </v-icon>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="aArticles.length > 0">
      <v-col
        v-for="(item, index) in aArticles"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        xl="2"
        lg="3"
      >
        <div class="card-container">
          <div class="content-image mb-2">
            <img
              v-if="item.aImages.length > 0"
              :src="item.aImages[0].oImages.md"
            />
            <img
              v-else
              src="@/assets/images/placeholder-image.jpeg"
            />
          </div>
          <div
            class="content-div-text"
            :style="{ height: `${restantHeightName}px` }"
          >
            <p class="text-article-name mon-semibold">
              {{ item.sName }}
            </p>
          </div>

          <div
            class="display-flex mt-2"
            style="align-items: flex-start"
            :style="{ height: `${resizeDivLocation}px` }"
          >
            <v-icon
              color="#9AA6B1"
              size="14px"
            > mdi-map-marker </v-icon>
            <p class="text-article-address mon-bold">
              {{ item.sLocationWarehouse }}
            </p>
          </div>
          <div class="display-flex align-items-center mt-2 mb-2">
            <div class="display-flex align-items-center mt-2 mb-2">
              <v-rating
                :value="item.dRating"
                length="5"
                color="#FFC92A"
                background-color="#C6C6C8"
                dense
                small
                readonly
              ></v-rating>
              <p
                :style="item.dRating == 0 ? { color: '#C6C6C8' } : {}"
                class="text-calif-num mon-bold ml-1 mb-n1"
              >
                {{ item.dRating > 5 ? 5 : item.dRating }}
              </p>
            </div>
            <v-spacer></v-spacer>
          </div>
          <div
            class="content-div-text"
            :style="{ height: `${restantHeightPrice}px` }"
          >
            <p class="text-article-price mon-bold">
              ${{ formatMoney(item.dUnitPrice) }} /
              {{ item.oMeasurementUnit.sName }}
            </p>
          </div>
          <div class="mt-2">
            <v-btn
              @click="articleDetail(item.sProductId)"
              class="button-view mon-bold"
              elevation="0"
            >
              {{ texts.home.article.textButtonView }}
              <v-icon
                color="#283C4D"
                size="14px"
                class="ml-1"
              >
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <div class="
            empty-content
            display-flex
            align-items-center
            justify-content-center
          ">
          <div>
            <!-- <div class="display-flex align-items-center justify-content-center">
              <img
                class="empty-image"
                src="@/assets/images/oops.png"
                alt=""
              >
            </div> -->
            <p class="empty-text mon-regular">
              {{ texts.home.article.empty.text }}
            </p>
            <div class="
                display-flex
                align-items-center
                justify-content-center
                mt-3
              ">
              <v-btn
                @click="validation"
                class="button-primary mon-medium"
                elevation="0"
              >
                {{ texts.home.article.empty.link }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeArticleLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aArticles: [],
      restantHeightCard: 0,
      restantHeightName: 0,
      restantHeightLocation: 0,
      restantHeightPrice: 0,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getArticles();
  },
  updated() {
    this.resizeDivName();
    this.resizeDivLocation();
    this.resizeDivPrice();
  },
  created() {
    window.addEventListener("resize", this.resizeCardHeight);
    this.resizeCardHeight();
    window.addEventListener("resize", this.resizeDivName);
    this.resizeDivName();
    window.addEventListener("resize", this.resizeDivLocation);
    this.resizeDivLocation();
    window.addEventListener("resize", this.resizeDivPrice);
    this.resizeDivPrice();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeCardHeight);
    window.removeEventListener("resize", this.resizeDivName);
    window.removeEventListener("resize", this.resizeDivLocation);
    window.removeEventListener("resize", this.resizeDivPrice);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    validation: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}`,
        config
      )
        .then((response) => {
          let arr = response.data.results;
          if (arr.bVerified) {
            DB.get(
              `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/warehouses`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {},
              }
            )
              .then((response) => {
                let arr = response.data.results;
                if (arr.length > 0) {
                  this.$router.push({
                    name: "NewArticle",
                  });
                  // this.$store.commit("setArticleDialogAdd", true);
                } else {
                  this.mixInfo(this.texts.home.withoutWarehouse);
                }
              })
              .catch((error) => {
                this.mixError(
                  error.response.data.message,
                  error.response.status
                );
              });
          } else {
            this.mixError(
              this.selectLanguage === "sp"
                ? "Tu empresa no ha sido verificada, contacta a PROPLAT."
                : "Your company has not been verified, contact PROPLAT."
            );
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    redirectArticles: function () {
      this.$router.push("/articles");
    },
    articleDetail: function (id) {
      this.$router.push(`/article/${id}`);
    },
    getArticles: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: 1,
          iItemsPerPage: 6,
        },
      })
        .then((response) => {
          this.aArticles = response.data.results;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    resizeCardHeight() {
      var contentHeight = document.getElementsByClassName("card-container");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);

      this.restantHeightCard = max;
    },
    resizeDivName: function (text) {
      this.$nextTick(() => {
        var contentHeight =
          document.getElementsByClassName("text-article-name");
        let tam = [];
        for (let i = 0; i < contentHeight.length; i++) {
          const element = contentHeight[i];
          tam.push(element.clientHeight);
        }
        let max = Math.max(...tam);
        this.restantHeightName = max;
      });
    },
    resizeDivLocation: function (text) {
      var contentHeight = document.getElementsByClassName(
        "text-article-address"
      );
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightLocation = max;
    },
    resizeDivPrice: function () {
      var contentHeight = document.getElementsByClassName("text-article-price");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightPrice = max;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getArticles();
      }
    },
    refresh: function () {
      this.getArticles();
    },
  },
};
</script>

<style scoped>
.scrolled-lateral {
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding: 10px 0px;
}
.content-div-card {
  background-color: red;
}
.text-article-name {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-article-address {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.button-view {
  width: 100%;
  height: 35px !important;
  background: transparent linear-gradient(260deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.text-article-price {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calification-number {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calification-texts {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.card-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  padding: 15px;
}

.text-address {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.text-phone {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.button-view-detail {
  background-color: #ffffff !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  height: 45px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.text-link {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0071ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-underline:hover {
  text-decoration: underline;
}

.scroll-horizontal {
  width: 100%;
  overflow: auto;
}

.content-image {
  width: 100%;
  height: 250px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

.text-title {
  text-align: left;
  font-size: 21px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .text-title {
    font-size: 20px;
    width: 100%;
  }
  .content-titles-and-links {
    display: block;
    margin-bottom: 10px;
  }
}
</style>