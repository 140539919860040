<template>
  <div>
    <v-container fluid>
      <!-- boton de regresar -->
      <v-row>
        <v-col>
          <div @click="$router.push({ name: 'Orders' })" style="width: fit-content"
            class="cursor-pointer display-flex align-items-center">
            <v-btn class="button-return-icon" icon>
              <v-icon color="#283C4D" size="16px"> mdi-arrow-left </v-icon>
            </v-btn>
            <p class="button-return-text mon-regular hover-underline ml-2">
              {{ texts.orders.orderTab.buttonReturn }}
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- id, fechas y estatus de la orden  -->
      <v-row>
        <v-col cols="12" md="3" sm="12">
          <div class="display-flex align-items-center height-100">
            <p class="text-number-order mon-bold">{{ aOrders.sFolioId }}</p>
          </div>
        </v-col>
        <v-col cols="12" md="2" sm="12">
          <div v-if="isResponsive" class="display-flex align-items-center height-100 flex-wrap">
            <p class="text-label text-start mon-regular">
              {{ texts.orders.orderTab.textCreation }}:
            </p>
            <p class="text-label-item mon-regular ml-2">
              {{ aOrders.created_at }}
            </p>
          </div>
          <div v-else class="align-items-center height-100 flex-wrap">
            <p class="text-label text-start mon-regular">
              {{ texts.orders.orderTab.textCreation }}
            </p>
            <p class="text-label-item mon-regular ml-1">
              {{ aOrders.created_at }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="4" sm="12">
          <div v-if="isResponsive" class="display-flex align-items-center height-100 content-dates">
            <div class="display-flex align-items-center">
              <p class="text-label text-start mon-regular">
                {{
                    bFinished
                      ? texts.orders.orderTab.textDateFinished
                      : texts.orders.orderTab.textDateLimit
                }}:
              </p>
              <p v-if="!bFinished" class="text-label-item mon-regular ml-2">
                {{
                    aOrders.tArrivalAtBorderWarehouse !== null
                      ? aOrders.tArrivalAtBorderWarehouse
                      : "N/A"
                }}
              </p>
              <p v-else class="text-label-item mon-regular ml-1">
                {{ aOrders.tPurchaseOrderStatusDate }}
              </p>
            </div>
          </div>
          <div v-else class="display-flex align-items-center height-100 content-dates">
            <div v-if="isResponsive" class="display-flex align-items-center">
              <p class="text-label text-start mon-regular">
                {{
                    bFinished
                      ? texts.orders.orderTab.textDateFinished
                      : texts.orders.orderTab.textDateLimit
                }}:
              </p>
              <p v-if="!bFinished" class="text-label-item mon-regular ml-2">
                {{
                    aOrders.tArrivalAtBorderWarehouse !== null
                      ? aOrders.tArrivalAtBorderWarehouse
                      : "N/A"
                }}
              </p>
              <p v-else class="text-label-item mon-regular ml-1">
                {{ aOrders.tPurchaseOrderStatusDate }}
              </p>
            </div>
            <div v-else class="align-items-center height-100 ml-5 flex-wrap">
              <p class="text-label text-start mon-regular">
                {{
                    bFinished
                      ? texts.orders.orderTab.textDateFinished
                      : texts.orders.orderTab.textDateLimit
                }}
              </p>
              <p v-if="!bFinished" class="text-label-item mon-regular ml-1">
                {{
                    aOrders.tArrivalAtBorderWarehouse !== null
                      ? aOrders.tArrivalAtBorderWarehouse
                      : "N/A"
                }}
              </p>
              <p v-else class="text-label-item mon-regular ml-1">
                {{ aOrders.tPurchaseOrderStatusDate }}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <div v-if="isResponsive">
            <div v-if="returnActive()">
              <div class="display-flex align-items-center height-100 content-gray">
                <p class="text-label text-start mon-regular">
                  {{ texts.orders.orderTab.textState }}
                </p>
                <v-spacer />
                <p class="text-label-item mon-regular">{{ orderStatus }}</p>
              </div>
            </div>
            <div v-else class="">
              <div class="display-flex align-items-center height-100 content-gray">
                <div>
                  <p class="text-label text-start mon-regular">
                    {{ texts.orders.orderTab.textState }}
                  </p>
                  <p class="text-label-item mon-regular">
                    {{ orderStatus }}
                  </p>
                </div>
                <v-spacer />
                <v-btn elevation="0" @click="updateState" class="button-update mon-bold ml-5">
                  {{ texts.orders.buttonUpdate }}
                </v-btn>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="returnActive()" class="
              display-flex
              align-items-center
              justify-content-flex-end
              height-100
            ">
              <div class="display-flex align-items-center height-100 content-gray">
                <p class="text-label text-start mon-regular">
                  {{ texts.orders.orderTab.textState }}
                </p>
                <p class="text-label-item mon-regular ml-2">{{ orderStatus }}</p>
              </div>
            </div>
            <div v-else class="
              display-flex
              align-items-center
              justify-content-flex-end
              height-100
            ">
              <div class="display-flex align-items-center height-100 content-gray">
                <div>
                  <p class="text-label text-start mon-regular">
                    {{ texts.orders.orderTab.textState }}
                  </p>
                  <p class="text-label-item mon-regular">
                    {{ orderStatus }}
                  </p>
                </div>
                <v-btn elevation="0" @click="updateState" class="button-update mon-bold ml-5">
                  {{ texts.orders.buttonUpdate }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- pedido -->
      <v-row v-if="isResponsive">
        <v-col cols="12">
          <div>
            <p class="text-label-content-shadow mon-bold mb-n4">
              {{ texts.orders.orderTab.setcionOrder.textTitle }}
            </p>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="content-shadow" style="border-radius: 0px;">
            <p class="text-warehouse-buyer mon-semibold mb-2">
              {{ aOrders.sCustomerEnterpriseName }}
            </p>
            <hr class="mb-2" />
            <div v-for="(item, index) in aOrders.aPurchaseProducts" :key="index"
              style="border-bottom: 1px solid gray; padding-bottom: 15px; padding-top: 15px;">
              <div class="display-flex align-items-center mb-4">
                <div class="content-image">
                  <img :src="item.sImageUrl" alt="" />
                </div>
                <p class="text-order-product mon-regular ml-2">
                  {{ item.sProductName }}
                </p>
              </div>
              <div class="display-flex align-items-center">
                <p class="text-order-label mon-regular">
                  {{ nameBox }} {{ texts.orders.orderTab.sectionArticle.text2 }}
                </p>
                <v-spacer />
                <p class="text-order-product mon-regular text-right">
                  {{ item.dDeliveredQuantity }}
                  {{ item.sMeasurementUnitName }}
                </p>
              </div>
              <div class="display-flex align-items-center">
                <p class="text-order-label mon-regular">
                  {{ sectionArticleText3() }}
                </p>
                <v-spacer />
                <p class="text-order-product mon-regular text-right">
                  ${{ formatMoney(item.dDeliveredUnitPrice) }} MXN
                </p>
              </div>
              <div class="display-flex align-items-center">
                <p class="text-order-label mon-regular">
                  {{ texts.orders.orderTab.sectionArticle.text4 }}
                </p>
                <v-spacer />
                <p class="text-order-product mon-regular text-right">
                  ${{ formatMoney(item.dFinalPrice) }} MXN
                </p>
              </div>
            </div>
            <!-- sutotal  -->
            <div
              style="background-color: rgba(49, 148, 255, 0.314); margin-left: -20px; margin-right: -20px; margin-bottom: -20px; height: 50px; display: flex; align-items: center;">
              <div class="display-flex align-items-center width-100">
                <p class="text-price-item mon-bold ml-2">Subtotal</p>
                <v-spacer />
                <p class="text-price-item mon-bold mr-2">
                  ${{ formatMoney(aOrders.dSubTotal) }} MXN
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div>
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.setcionOrder.textTitle }}
            </p>
            <div class="content-shadow">
              <p class="text-warehouse-buyer mon-semibold">
                {{ aOrders.sCustomerEnterpriseName }}
              </p>
              <div class="border-bottom" />
              <v-container fluid>
                <v-row>
                  <v-col>
                    <p class="text-order-label mon-regular text-left">
                      {{ texts.orders.orderTab.sectionArticle.text1 }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-label mon-regular text-right">
                      {{ this.nameBox }}
                      {{ texts.orders.orderTab.sectionArticle.text2 }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-label mon-regular text-right">
                      {{ sectionArticleText3() }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-label mon-regular text-right">
                      {{ texts.orders.orderTab.sectionArticle.text4 }}
                    </p>
                  </v-col>
                </v-row>
                <v-row v-for="(item, index) in aOrders.aPurchaseProducts" :key="index">
                  <v-col>
                    <div class="display-flex align-items-center">
                      <div class="content-image">
                        <img :src="item.sImageUrl" alt="" />
                      </div>
                      <p class="text-order-product mon-regular ml-2">
                        {{ item.sProductName }}
                      </p>
                    </div>
                  </v-col>
                  <v-col>
                    <p class="text-order-product mon-regular text-right">
                      {{ item.dDeliveredQuantity }}
                      {{ item.sMeasurementUnitName }}
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-product mon-regular text-right">
                      ${{ formatMoney(item.dDeliveredUnitPrice) }} MXN
                    </p>
                  </v-col>
                  <v-col>
                    <p class="text-order-product mon-regular text-right">
                      ${{ formatMoney(item.dFinalPrice) }} MXN
                    </p>
                  </v-col>
                </v-row>
              </v-container>
              <div class="border-bottom" />
              <div class="
                  display-flex
                  align-items-center
                  justify-content-flex-end
                  mt-4
                ">
                <p class="text-price mon-bold">Subtotal:</p>
                <p class="text-price-item mon-bold ml-2">
                  ${{ formatMoney(aOrders.dSubTotal) }} MXN
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- costos de envio y archivos del y para el cliente -->
      <v-row>
        <v-col cols="12" sm="6">
          <div>
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionCostSend.textTitle }}
            </p>
            <div v-for="(item, index) in aOrders.aSalesOrderFees" :key="index">
              <div v-if="item.sFeeId == '64d6ff43-bc6d-4ded-bf62-0d11e05ef4b0'" class="
                  content-shadow
                  display-flex
                  align-items-center
                  pl-2
                  pr-2
                  pt-8
                  pb-8
                ">
                <p class="text-cost-mexico mon-regular">{{ item.sFeeName }}</p>
                <v-spacer></v-spacer>
                <p class="text-cost-mexico-price mon-regular">
                  ${{ formatMoney(item.dTotal) }} MXN
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div>
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionFile.textTitle }}
            </p>
            <div class="content-shadow pa-0">
              <!-- factura de pago -->
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-files-client-name mon-regular">
                  {{ texts.orders.factura }}
                </p>
                <v-spacer />
                <div v-if="filesClientInvoiceFile !== null" style="width: 80%" class="
                    display-flex
                    align-items-center
                    justify-content-center
                    height-100
                    remove-center
                  ">
                  <p @click="openFile(filesClientInvoiceFile)" class="text-download mon-regular">
                    <!-- {{ texts.orders.descargar }} -->
                    {{ facturaName }}
                  </p>
                  <v-btn icon>
                    <v-icon color="#D8686A" size="14px" @click="deleteInvoice">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </div>
                <div v-else style="width: 80%"
                  class="display-flex align-items-center justify-content-center remove-center">
                  <v-btn @click="clickInputInvoice" class="button-add-document-invoice mon-medium" elevation="0"
                    :loading="bLoadingInvoice">
                    {{ texts.orders.addDFile }}
                    <input ref="inputUploadInvoice" id="inputUploadInvoice" style="display: none" type="file" size="60"
                      accept=".doc, .docx, .pdf" @change="uploadInvoice($event)" />
                  </v-btn>
                </div>
              </div>
              <!-- comprobante de pago -->
              <div class="display-flex align-items-center pa-2">
                <p class="text-files-client-name mon-regular">
                  {{ texts.orders.comprobante }}
                </p>
                <v-spacer />
                <p v-if="filesClientPaymentFile !== null" @click="openFile(filesClientPaymentFile)"
                  class="text-download mon-regular hasta-el-final" style="width: 80%">
                  <!-- {{ texts.orders.descargar }} -->
                  {{ comprobanteName }}
                </p>
                <p v-else style="opacity: 0.5" class="text-center text-download-placeholder mon-regular hasta-el-final">
                  {{
                      selectLanguage == "sp"
                        ? "No hay documentos para descargar"
                        : "There is not document to download"
                  }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- fechas y total de la orden -->
      <v-row>
        <v-col>
          <div class="content-shadow padding-lateral-max">
            <div class="display-flex align-items-center height-100">
              <div>
                <p class="text-label-payments mon-bold">
                  {{ texts.orders.orderTab.textPay }}
                </p>
                <p class="text-label-payments-item mon-regular">
                  {{ aOrders.sPaid }}
                </p>
              </div>
              <v-spacer />
              <div>
                <p class="text-label-payments mon-bold">
                  {{ texts.orders.orderTab.paymentMethod }}
                </p>
                <img
                  v-if="aOrders.sPaymentMethodName === 'Crédito Proplat' || aOrders.sPaymentMethodName === 'Proplat credit'"
                  class="img-paymentmethod" src="@/assets/images/CreditProplat.png" />
                <p v-else class="text-label-payments-item mon-regular">
                  {{ aOrders.sPaymentMethodName }}
                </p>
              </div>
              <v-spacer />
              <div>
                <p class="text-label-payments mon-bold">
                  {{ texts.orders.orderTab.textTotalOrder }}
                </p>
                <p class="text-label-payments-item mon-regular">
                  ${{ formatMoney(aOrders.dTotalPrice) }} MXN
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- archivos requeridos -->
      <v-row v-if="aOrders.aFilesByStatus && aOrders.aFilesByStatus.length">
        <v-col>
          <div v-if="isResponsive">
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionFiles.textTitle1 }}
            </p>
            <div class="content-shadow">
              <div style="height: 100px; opacity: 0.5" class="
                  display-flex
                  align-items-center
                  justify-content-center
                  mon-regular
                " v-if="existRequiredFiles == 0">
                {{
                    selectLanguage == "sp"
                      ? "Aún no hay archivos para mostrar."
                      : "There are no files to show yet."
                }}
              </div>
              <div v-else>
                <div v-for="(item, index) in aFilesByStatus" :key="index"
                  style="border-bottom: 1px solid gray; padding-top: 15px; padding-bottom: 15px;">
                  <div class="display-flex align-items-center justify-content-flex-start height-100 mt-2">
                    <p class="text-files-header mon-bold text-left">
                      {{ texts.orders.orderTab.sectionFiles.headerRequiredFiles.text1 }}
                    </p>
                    <v-spacer />
                    <p class="text-name-file mon-regular text-left">
                      {{ item.sPurchaseOrderFileTypeName }}
                    </p>
                  </div>
                  <!-- upload file and link document  -->
                  <div v-if="item.sFileUrl !== null"
                    class="display-flex align-items-center justify-content-center height-100 mt-2">
                    <p class="text-files-header mon-bold text-center">
                      {{
                          texts.orders.orderTab.sectionFiles.headerRequiredFiles
                            .text2
                      }}
                    </p>
                    <v-spacer />
                    <p class="text-download mon-regular text-center" @click="openFile(item.sFileUrl)">
                      {{ item.sFileKey }}
                    </p>
                  </div>
                  <v-btn v-else @click="clickInputFile(index)" class="button-add-document mon-medium mt-2" elevation="0"
                    :loading="item.bLoadingFiles">
                    {{ texts.orders.addDFile }}
                    <input :ref="'inputUploadFile' + index" :id="'inputUploadFile' + index" style="display: none"
                      type="file" size="60" accept=".doc, .docx, .pdf" @change="uploadFilesWarehouse($event, item)" />
                  </v-btn>
                  <!-- button delete file  -->
                  <v-btn elevation="0" v-if="item.sFileUrl !== null"
                    @click="deleteDocument(item.sPurchaseOrderFileTypeId)" class="button-tertiary"
                    style="width: 100%; margin-top: 10px;">
                    {{
                        (selectLanguage == 'sp')
                          ? 'Eliminar'
                          : 'Delete'
                    }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionFiles.textTitle1 }}
            </p>
            <div class="content-shadow">
              <v-row>
                <v-col cols="12" md="5" sm="4">
                  <div>
                    <p class="text-files-header mon-bold text-left">
                      {{
                          texts.orders.orderTab.sectionFiles.headerRequiredFiles
                            .text1
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="5" sm="4">
                  <div>
                    <p class="text-files-header mon-bold text-center">
                      {{
                          texts.orders.orderTab.sectionFiles.headerRequiredFiles
                            .text2
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="2" sm="4">
                  <div>
                    <p class="text-files-header mon-bold text-right">
                      {{
                          texts.orders.orderTab.sectionFiles.headerRequiredFiles
                            .text3
                      }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <div style="border: 1px solid #949494" />
                </v-col>
              </v-row>
              <div style="height: 100px; opacity: 0.5" class="
                  display-flex
                  align-items-center
                  justify-content-center
                  mon-regular
                " v-if="existRequiredFiles == 0">
                {{
                    selectLanguage == "sp"
                      ? "Aún no hay archivos para mostrar."
                      : "There are no files to show yet."
                }}
              </div>
              <div v-else>
                <v-row class="mt-0" v-for="(item, index) in aFilesByStatus" :key="index">
                  <v-col cols="12" md="5" sm="4">
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-flex-start
                        height-100
                      ">
                      <p class="text-name-file mon-regular text-left">
                        {{ item.sPurchaseOrderFileTypeName }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" md="5" sm="4">
                    <div v-if="item.sFileUrl !== null" class="
                        display-flex
                        align-items-center
                        justify-content-center
                        height-100
                      ">
                      <p class="text-download mon-regular text-center" @click="openFile(item.sFileUrl)">
                        {{ item.sFileKey }}
                      </p>
                    </div>
                    <v-btn v-else @click="clickInputFile(index)" class="button-add-document mon-medium" elevation="0"
                      :loading="item.bLoadingFiles">
                      {{ texts.orders.addDFile }}
                      <input :ref="'inputUploadFile' + index" :id="'inputUploadFile' + index" style="display: none"
                        type="file" size="60" accept=".doc, .docx, .pdf" @change="uploadFilesWarehouse($event, item)" />
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2" sm="4">
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-flex-end
                        height-100
                      ">
                      <v-btn @click="deleteDocument(item.sPurchaseOrderFileTypeId)" icon>
                        <v-icon color="#D8686A" size="16px">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- historial de archivos  -->
      <v-row>
        <v-col>
          <div v-if="isResponsive">
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionFiles.textTitle2 }}
            </p>
            <div class="content-shadow">
              <div style="height: 100px; opacity: 0.5" class="
                  display-flex
                  align-items-center
                  justify-content-center
                  mon-regular
                " v-if="existFiles == 0">
                {{
                    selectLanguage == "sp"
                      ? "Aún no hay archivos para mostrar."
                      : "There are no files to show yet."
                }}
              </div>
              <div v-else>
                <div v-for="(item, index) in aOrders.aPurchaseOrderRecordFiles" :key="index"
                  style="border-bottom: 1px solid gray; padding-top: 15px; padding-bottom: 15px;">
                  <div class="display-flex align-items-center justify-content-flex-start height-100">
                    <p class="text-files-header mon-bold text-left">
                      {{ texts.orders.orderTab.sectionFiles.headerHistorialFiles.text1 }}
                    </p>
                    <v-spacer />
                    <p class="text-name-file mon-regular text-left">
                      {{ item.sPurchaseOrderFileTypeName }}
                    </p>
                  </div>
                  <div class="display-flex align-items-center justify-content-center height-100">
                    <p class="text-files-header mon-bold text-center">
                      {{ texts.orders.orderTab.sectionFiles.headerHistorialFiles.text2 }}
                    </p>
                    <v-spacer />
                    <p class="text-name-file mon-regular text-left">
                      {{ item.created_at }}
                    </p>
                  </div>
                  <div class="
                        display-flex
                        align-items-center
                        justify-content-center
                        height-100
                      ">
                    <p class="text-files-header mon-bold text-center">
                      {{ texts.orders.orderTab.sectionFiles.headerHistorialFiles.text3 }}
                    </p>
                    <v-spacer />
                    <p class="text-name-file mon-regular text-left">
                      {{ item.sPurchaseOrderStatusName }}
                    </p>
                  </div>
                  <v-btn elevation="0" style="width: 100%;" class="button-primary" @click="openFile(item.sFileURL)">
                    {{
                        (selectLanguage == 'sp')
                          ? 'Descargar'
                          : 'Download'
                    }}
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionFiles.textTitle2 }}
            </p>
            <div class="content-shadow">
              <v-row>
                <v-col cols="12" md="3" sm="3">
                  <div>
                    <p class="text-files-header mon-bold text-left">
                      {{
                          texts.orders.orderTab.sectionFiles.headerHistorialFiles
                            .text1
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <p class="text-files-header mon-bold text-center">
                    {{
                        texts.orders.orderTab.sectionFiles.headerHistorialFiles
                          .text2
                    }}
                  </p>
                  <div></div>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <div>
                    <p class="text-files-header mon-bold text-center">
                      {{
                          texts.orders.orderTab.sectionFiles.headerHistorialFiles
                            .text3
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <div>
                    <p class="text-files-header mon-bold text-right">
                      {{
                          texts.orders.orderTab.sectionFiles.headerHistorialFiles
                            .text4
                      }}
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <div style="border: 1px solid #949494" />
                </v-col>
              </v-row>
              <div style="height: 100px; opacity: 0.5" class="
                  display-flex
                  align-items-center
                  justify-content-center
                  mon-regular
                " v-if="existFiles == 0">
                {{
                    selectLanguage == "sp"
                      ? "Aún no hay archivos para mostrar."
                      : "There are no files to show yet."
                }}
              </div>
              <div v-else>
                <v-row v-for="(item, index) in aOrders.aPurchaseOrderRecordFiles" :key="index" class="mt-0">
                  <v-col cols="12" sm="3" md="3">
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-flex-start
                        height-100
                      ">
                      <p class="text-name-file mon-regular text-left">
                        {{ item.sPurchaseOrderFileTypeName }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-center
                        height-100
                      ">
                      <p class="text-name-file mon-regular text-left">
                        {{ item.created_at }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-center
                        height-100
                      ">
                      <p class="text-name-file mon-regular text-left">
                        {{ item.sPurchaseOrderStatusName }}
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-flex-end
                        height-100
                      ">
                      <v-btn icon @click="openFile(item.sFileURL)">
                        <v-icon color="#000" size="16px"> mdi-download </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div>
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionWarehouse.textTitle }}
            </p>
            <div class="content-shadow">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="3">
                    <div>
                      <div>
                        <p class="text-warehouse-label mon-bold">
                          {{
                              texts.orders.orderTab.sectionWarehouse.textTitleSend
                          }}
                        </p>
                        <p class="text-warehouse-label-item mon-regular">
                          {{ nameWarehouseEnv }}
                        </p>
                      </div>
                      <div class="mt-9">
                        <p class="text-warehouse-label mon-bold">
                          {{
                              texts.orders.orderTab.sectionWarehouse.textTitleEnt
                          }}
                        </p>
                        <p class="text-warehouse-label-item mon-regular">
                          {{ nameWarehouseEnt }}
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="9">
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-center
                      ">
                      <GmapMap :center="{ lat: latOrigin, lng: lngOrigin }" :zoom="4" :options="mapOptions"
                        style="width: 100%; height: 350px">
                        <GmapMarker :position="{ lat: latOrigin, lng: lngOrigin }" />
                        <GmapMarker :position="{
                          lat: latDestination,
                          lng: lngDestination,
                        }" />
                      </GmapMap>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- <v-row> 
        <v-col>
          <div>
            <p class="text-label-content-shadow mon-bold">{{ texts.orders.orderTab.sectionPaymentMethod.textTitle}}</p>
            <div class="content-shadow">
              <p class="text-payment-method mon-regular">{{ aOrders.sPaymentMethodName }}</p>
            </div>
          </div>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <div class="mb-9">
            <p class="text-label-content-shadow mon-bold">
              {{ texts.orders.orderTab.sectionFollow.textTitle }}
            </p>
            <div class="content-shadow seguimiento-panel">
              <v-expansion-panels accordion v-model="panel">
                <v-expansion-panel v-for="(item, index) in aOrders.aPurchaseOrderStatusRecord" :key="index">
                  <v-expansion-panel-header>
                    <div class="
                        display-flex
                        align-items-center
                        justify-content-center
                        number-section
                      ">
                      {{ index + 1 }}
                    </div>
                    <p class="mon-medium text-title-step ml-7" style="margin-bottom: 0px">
                      {{ item.sPurchaseOrderStatusName }} -
                      <span class="mon-regular">{{ item.tCreatedAt }}</span>
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div>
                          <p class="text-description-seguimiento mon-medium">
                            {{ item.sDescription }}
                          </p>
                        </div>
                      </v-col>
                      <v-col :style="
                        item.aPurchaseOrderStatusRecordImages.length > 0
                          ? { display: 'block' }
                          : { display: 'none' }
                      " cols="12" md="12">
                        <div class="pt-2 pb-2 container-carousel">
                          <div class="
                              content-carousel-effect
                              display-flex
                              align-items-center
                            ">
                            <div class="mr-5" v-for="(
                                item_image, index_image
                              ) in item.aPurchaseOrderStatusRecordImages" :key="index_image">
                              <div @click="
                                openCarousel(
                                  index_image,
                                  item.aPurchaseOrderStatusRecordImages
                                )
                              " class="content-image-carousel cursor-pointer">
                                <img :src="item_image.oImages.md" alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-col>
      </v-row>
      <br />
    </v-container>
    <order-detail-accept-layout v-if="aOrders.sPurchaseOrderStatusId == 'dac89b64-079a-4c71-b894-49436e0b61d5'"
      :texts="texts" :salesOrderId="aOrders.sSalesOrderId" />
  </div>
</template>

<script>
export default {
  name: "OrderInProcessContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aOrders: [],
      filesClient: [],
      orderStatus: "",
      orderOriginLocation: "",
      orderDestinationLocation: "",
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      panel: 0,
      imageSelected: "",
      withoutImages: "",
      nameWarehouseEnv: "",
      nameWarehouseEnt: "",
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 5,
        minZoom: 3,
      },
      latOrigin: 0,
      lngOrigin: 0,
      latDestination: 0,
      lngDestination: 0,
      aFilesByStatus: [],
      bLoadingInvoice: false,
      filesClientInvoiceFile: null,
      filesClientPaymentFile: null,
      nameBox: "",
      existFiles: 0,
      existRequiredFiles: 0,
      bFinished: false,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getOrdersInProcess();
    this.locateGeoLocation();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    openFile: function (e) {
      window.open(e, "blank");
    },
    returnActive: function () {
      let active = null;

      if (
        this.aOrders.sPurchaseOrderStatusId ==
        "37a8cdd3-ef55-4bd5-a310-9bea096ffc15" ||
        this.aOrders.sPurchaseOrderStatusId ==
        "6639b3de-c4d0-4db3-8788-091082e5a7c3"
      ) {
        active = false;
      } else {
        active = true;
      }

      return active;
    },
    sectionArticleText3: function () {
      let text = "";
      if (Object.values(this.aOrders).length > 0)
        if (this.selectLanguage == "sp") {
          text = "Precio por " + this.nameBox + " solicitado(a)";
        } else {
          text = "Price per " + this.nameBox + " requested";
        }
      return this.capitalize(text);
    },
    capitalize: function (str) {
      let lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice("1");
    },
    clickInputFile: function (index) {
      var inputUploadFile = "inputUploadFile" + index;
      document.getElementById(inputUploadFile).click();
    },
    clickInputInvoice: function () {
      this.$refs.inputUploadInvoice.click();
    },
    openCarousel: function (imgSelected, allImages) {
      this.$store.commit("setGallery", {
        active: true,
        imageSelected: imgSelected,
        imagesArr: allImages,
      });
    },
    deleteDocument: function (fileId) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.orders.titleDelete,
        api: `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.$route.params.id}/records/${this.aOrders.sPurchaseOrderStatusRecordId}/files/${fileId}`,
        redirect: "",
      });
    },
    uploadFilesWarehouse: function (e, item) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.saveFile(files[0], item);
          item.bLoadingFiles = true;
        });
      }
    },
    saveFile: function (file, item) {
      const payload = {};
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.$route.params.id}/records/${this.aOrders.sPurchaseOrderStatusRecordId}/files/${item.sPurchaseOrderFileTypeId}`,
        payload,
        config
      )
        .then((response) => {
          this.uploadFiles(file, item, response.data.message);
        })
        .catch((error) => {
          item.bLoadingFiles = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    uploadFiles(file, item, message) {
      var form = new FormData();
      form.append("file", file);

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.$route.params.id}/records/${this.aOrders.sPurchaseOrderStatusRecordId}/files/${item.sPurchaseOrderFileTypeId}`,
        form,
        config
      )
        .then((response) => {
          this.mixSuccess(message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          item.bLoadingFiles = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // --- start: upload document --- //
    uploadInvoice: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.saveInvoice(files[0]);
          this.bLoadingInvoice = true;
        });
      }
    },
    saveInvoice(file) {
      var form = new FormData();
      form.append("file", file);

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.$route.params.id}/files/${this.filesClient[0].sPurchaseOrderFileTypeId}`,
        form,
        config
      )
        .then((response) => {
          this.bLoadingInvoice = false;

          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoadingInvoice = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    deleteInvoice: function () {
      let factura = this.filesClient.filter((e) => {
        return (
          e.sPurchaseOrderFileTypeId == "ba30e772-cbcf-4b89-b6fa-54ecefb26185"
        );
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const payload = {};

      DB.delete(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.$route.params.id}/files/${factura[0].sPurchaseOrderFileTypeId}`,
        config,
        payload
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // --- end: upload document --- //
    selectImage: function (e) {
      this.imageSelected = e.sImageUrl;
    },
    extractImageSelected: function () {
      let img_selected = "";

      this.aOrders.aPurchaseOrderStatusRecord.forEach((element) => {
        if (element.aPurchaseOrderStatusRecordImages.length > 1) {
          img_selected = element.aPurchaseOrderStatusRecordImages[0].oImages.lg;
        }
      });

      this.imageSelected = img_selected;
    },
    updateState: function () {
      this.$store.commit("setDialogUpdateStatus", {
        active: true,
        arr: this.aOrders,
      });
    },
    initMarker(loc) {
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        };
        this.locationMarkers.push({ position: marker });
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    formatDate: function (date) {
      var format_date = MOMENT(new Date(date)).format("MMM. DD, YYYY. hh:mm A");
      return format_date;
    },
    formatDate1: function (date) {
      var new_date = MOMENT(date, "DD/DD/YYYY");
      var format_date = MOMENT(new_date).format("MMM. DD, YYYY. hh:mm A");
      return format_date;
    },
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    getOrdersInProcess: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.$route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          let arr = response.data.results;
          this.bFinished =
            arr.sPurchaseOrderStatusId ===
            "18b984e7-2295-40d8-aaa1-8f4f89fdaed8" ||
            arr.sPurchaseOrderStatusId ===
            "38b51cdd-95ca-4870-acde-68ed35597adb";

          this.aFilesByStatus = arr.aFilesByStatus.map((e) => {
            return {
              ...e,
              bLoadingFiles: false,
            };
          });

          this.aOrders = arr;
          this.orderStatus = arr.sPurchaseOrderStatusName;
          this.filesClient = arr.aGeneralFiles;

          let comprobante = arr.aGeneralFiles.filter((e) => {
            return (
              e.sPurchaseOrderFileTypeId ==
              "3ac4aa97-7f69-4314-bec2-346b808234b9"
            );
          });

          let factura = arr.aGeneralFiles.filter((e) => {
            return (
              e.sPurchaseOrderFileTypeId ==
              "ba30e772-cbcf-4b89-b6fa-54ecefb26185"
            );
          });

          this.filesClientInvoiceFile = factura[0].sUrlFile;
          this.filesClientPaymentFile = comprobante[0].sUrlFile;

          this.facturaName = factura[0].sPurchaseOrderFileTypeName;
          this.comprobanteName = comprobante[0].sPurchaseOrderFileTypeName;

          this.orderOriginLocation = arr.oOrigin.sLocation;
          this.orderDestinationLocation = arr.oDestination.sLocation;
          this.panel = arr.aPurchaseOrderStatusRecord.length - 1;
          this.nameWarehouseEnv = arr.oOrigin.sLocation;
          this.nameWarehouseEnt = arr.oDestination.sLocation;
          this.latOrigin = arr.oOrigin.dLatitude;
          this.lngOrigin = arr.oOrigin.dLongitude;
          this.latDestination = arr.oDestination.dLatitude;
          this.lngDestination = arr.oDestination.dLongitude;
          this.existFiles = arr.aPurchaseOrderRecordFiles.length;
          this.existRequiredFiles = arr.aFilesByStatus;
          this.nameBox = arr.aPurchaseProducts[0].sMeasurementUnitName;
          this.extractImageSelected();

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getOrdersInProcess();
      }
    },
    refresh: function () {
      this.getOrdersInProcess();
    },
  },
};
</script>

<style>
.seguimiento-panel .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #000000;
}
</style>

<style scoped>

.img-paymentmethod{
  width: 150px;
  margin-top: 10px;
}

.text-cost-mexico {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-cost-mexico-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.text-download:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-files-client-name {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

/* start: carousel images */
.content-carousel-effect {
  overflow-x: auto;
  overflow-y: hidden !important;
}

.content-carousel-effect::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content-carousel-effect {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.container-carousel {
  width: 100%;
}

.content-image-carousel {
  width: 90px;
  height: 90px;
}

.content-image-carousel img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-carousel img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }
}

.button-add-document {
  width: 100% !important;
  height: 30px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
  position: relative;
  text-transform: initial;
  font-size: 12px;
}

.button-add-document-invoice {
  width: 50% !important;
  height: 30px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
  position: relative;
  text-transform: initial;
  font-size: 12px;
}

.text-description-seguimiento {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.seguimiento-panel .v-expansion-panel-header {
  padding: 0px;
}

.seguimiento-panel .content-left-line {
  border-left: 1px solid #00000029;
  margin-left: -15px;
  padding: 0px 10px;
}

.seguimiento-panel .text-title-step {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.seguimiento-panel .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none;
}

.seguimiento-panel .v-stepper--vertical {
  padding-bottom: 0px;
}

.number-section {
  background-color: black;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  position: absolute;
  margin-right: 10px;
}

.seguimiento-panel .v-expansion-panel::before {
  box-shadow: none !important;
}

.text-files-header {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-download {
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  margin-bottom: 0px;
}

.text-download-placeholder {
  width: 80%;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-name-file {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.button-update {
  width: 90px;
  height: 30px !important;
  background: transparent linear-gradient(253deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #283c4d;
  margin-left: 5px;
}

.hover-underline:hover {
  text-decoration: underline;
}

.button-return-icon {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}

.button-return-text {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
  margin-right: 30px;
}

.text-price-item {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.content-image {
  width: 40px;
  height: 40px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }
}

.text-order-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-order-product {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-warehouse-buyer {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.text-payment-method {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.border-bottom {
  border-bottom: 1px solid #dddddd;
}

.text-warehouse-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-warehouse-label-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 0px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-label-content-shadow {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 5px;
}

.content-gray {
  background: #ebebeb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px 15px;
}

.padding-lateral-max {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.text-label-payments {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-label-payments-item {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-number-order {
  text-align: left;
  font-size: 25px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.content-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}

.text-label {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-label-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.content-fixed-image {
  width: 125px;
  max-height: 280px;
  overflow: auto;
  padding: 10px;
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-resize-image {
  width: calc(100% - 125px);
  height: 280px;
  padding: 10px;
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .resize-content {
    display: block;
  }

  .content-fixed-image {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .content-fixed-image .content-image {
    width: 130px;
    margin: 1px;
    padding: 4px;
  }

  .content-resize-image {
    width: 100% !important;
  }

  .content-dates {
    display: block;
    height: auto;
  }

  .content-dates div:nth-child(2) {
    margin-left: 0px !important;
    text-align: left;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}

@media (max-width: 960px) {
  .text-download {
    width: 180px;
  }

  .remove-center {
    justify-content: flex-end !important;
  }

  .hasta-el-final {
    text-align: right !important;
  }
}
</style>