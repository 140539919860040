import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import axios from "axios";
import { mixs } from "@/mixins";
import { texts } from "@/files";
import moment from "moment";
import Toast from "vue-toastification";
import lodash from "lodash";
import * as VueGoogleMaps from "vue2-google-maps";
import VueCurrencyInput from "vue-currency-input";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import "vue-toastification/dist/index.css";
import "vue-gifplayer/src/gif.css";
import "@/modules";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue2-timepicker/dist/VueTimepicker.css";

Vue.config.productionTip = false;

// import of mixins for the alert call
Vue.mixin(mixs);

//import lodash
window.lodash = lodash;

// use of alerts throughout the project
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyArzew42XOo4jWF_0fWBPa-lEhpFn6-OS0",
    libraries: "places", //necessary for places input
  },
});

Vue.use(VueCurrencyInput, {
  // locale: "en",
  // currency: "USD",
  // valueAsInteger: false,
  // distractionFree: false,
  // precision: 2,
  // autoDecimalMode: true,
  // allowNegative: false,

  // locale: "en-US",
  // currency: "USD",
  // currencyDisplay: "hidden",
  // precision: 2,
  // hideCurrencySymbolOnFocus: true,
  // hideGroupingSeparatorOnFocus: true,
  // hideNegligibleDecimalDigitsOnFocus: true,
  // autoDecimalDigits: false,
  // autoSign: false,
  // useGrouping: true,
  // accountingSign: true

  locale: "en-US",
  currency: "USD",
  currencyDisplay: "hidden",
  hideCurrencySymbolOnFocus: false,
  hideGroupingSeparatorOnFocus: true,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: false,
  autoSign: true,
  useGrouping: true,
  accountingSign: false,
});

Vue.use(VueTimepicker);

// import of project text files
window.FILE = texts;

// import axios
window.DB = axios;

window.MOMENT = moment;

// initailize URI for global use
//qa --- dev --- alfa
window.URI = 'https://api.proplat.com/qa';

window.FORMAT_MONEY = function(number) {
  let convert_number = Number(number).toFixed(2);
  return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
