<template>
  <v-container fluid>
    <v-row v-if="aArticles.length > 0">
      <v-col
        v-for="(item, index) in aArticles"
        :key="index"
        cols="12"
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <div class="card-container">
          <div v-if="!item.bVisible" class="disponibility-article">
            <p class="mon-regular">
              {{ texts.article.pause }}
            </p>
          </div>
          <div class="content-image mb-2">
            <img
              v-if="item.aImages.length > 0"
              :src="item.aImages[0].oImages.md"
              alt=""
            />
            <img v-else src="@/assets/images/placeholder-image.jpeg" alt="" />
          </div>
          <div
            class="content-div-text"
            :style="{ height: `${restantHeightName}px` }"
          >
            <p class="text-article-name mon-semibold">
              {{ item.sName }}
            </p>
          </div>
          <div
            class="display-flex mt-2"
            style="align-items: flex-start"
            :style="{ height: `${restantHeightLocation}px` }"
          >
            <v-icon color="#9AA6B1" size="14px"> mdi-map-marker </v-icon>
            <p class="text-article-address mon-bold">
              {{ item.sLocationWarehouse }}
            </p>
          </div>
          <div class="display-flex align-items-center mt-2 mb-2">
            <v-rating
              :value="item.dRating"
              length="5"
              color="#FFC92A"
              background-color="#C6C6C8"
              dense
              small
              readonly
            ></v-rating>
            <p
              :style="item.dRating == 0 ? { color: '#C6C6C8' } : {}"
              class="text-calif-num mon-bold ml-1 mb-n1"
            >
              {{ item.dRating > 5 ? 5 : item.dRating }}
            </p>
          </div>
          <div
            class="content-div-text"
            :style="{ height: `${restantHeightPrice}px` }"
          >
            <p class="text-article-price mon-bold">
              ${{ formatMoney(item.dUnitPrice) }} /
              {{ item.oMeasurementUnit.sName }}
            </p>
          </div>
          <div class="mt-2">
            <v-btn
              @click="articleDetail(item.sProductId)"
              class="button-view mon-bold"
              elevation="0"
            >
              {{ texts.article.textButtonView }}
              <v-icon color="#283C4D" size="14px" class="ml-1">
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <div class="display-flex align-items-center justify-content-center">
          <div class="pagination-styles">
            <v-pagination
              v-model="iCurrentPage"
              :length="lengthPage"
              color="#FFC556"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <div>
          <div
            style="height: 350px !important; margin-bottom: 60px"
            class="
              card-content
              display-flex
              align-items-center
              justify-content-center
            "
          >
            <div>
              <!-- <div class="display-flex align-items-center justify-content-center">
                <img
                  class="empty-image"
                  src="@/assets/images/oops.png"
                  alt=""
                >
              </div> -->
              <p class="empty-text mon-regular">{{ texts.article.empty }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ArticleLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      aArticles: [],
      lengthPage: 0,
      iCurrentPage: 1,
      restantHeightName: 0,
      restantHeightLocation: 0,
      restantHeightPrice: 0,
    };
  },
  beforeMount() {
    this.getArticles();
  },
  updated() {
    this.resizeDivName();
    this.resizeDivLocation();
    this.resizeDivPrice();
  },
  created() {
    window.addEventListener("resize", this.resizeDivName);
    this.resizeDivName();
    window.addEventListener("resize", this.resizeDivLocation);
    this.resizeDivLocation();
    window.addEventListener("resize", this.resizeDivPrice);
    this.resizeDivPrice();
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeDivName);
    window.removeEventListener("resize", this.resizeDivLocation);
    window.removeEventListener("resize", this.resizeDivPrice);
  },
  methods: {
    formatMoney: function (money) {
      return FORMAT_MONEY(money);
    },
    articleDetail: function (id) {
      this.$router.push(`/article/${id}`);
    },
    getArticles: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: 12,
          sCategoryId: this.sCategoryIdFilter,
          sSubcategoryId: this.sSubcategoryIdFilter,
          sSearch: this.sSearch,
          sAlphOrder: this.sortArticleAlpha,
          sPriceOrder: this.sortArticlePrice,
          dRatingMin: this.sortArticleRating,
        },
      })
        .then((response) => {
          this.aArticles = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    resizeDivName: function (text) {
      var _text = document.getElementsByClassName("text-article-name");
      let tam = [];
      for (let i = 0; i < _text.length; i++) {
        const element = _text[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightName = max;
    },
    resizeDivLocation: function (text) {
      var contentHeight = document.getElementsByClassName(
        "text-article-address"
      );
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightLocation = max;
    },
    resizeDivPrice: function () {
      var contentHeight = document.getElementsByClassName("text-article-price");
      let tam = [];
      for (let i = 0; i < contentHeight.length; i++) {
        const element = contentHeight[i];
        tam.push(element.clientHeight);
      }
      let max = Math.max(...tam);
      this.restantHeightPrice = max;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
    sSearch: function () {
      return this.$store.state.sSearch;
    },
    sFilter: function () {
      return this.$store.state.sFilter;
    },
    sCategoryIdFilter: function () {
      return this.$store.state.sCategoryIdFilter;
    },
    sSubcategoryIdFilter: function () {
      return this.$store.state.sSubcategoryIdFilter;
    },
    sortArticlePrice: function () {
      return this.$store.state.sortArticlePrice;
    },
    sortArticleAlpha: function () {
      return this.$store.state.sortArticleAlpha;
    },
    sortArticleRating: function () {
      return this.$store.state.sortArticleRating;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getArticles();
      }
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    sCategoryIdFilter: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    sSubcategoryIdFilter: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    iCurrentPage: function () {
      this.getArticles();
    },
    refresh: function () {
      this.getArticles();
    },
    sortArticlePrice: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    sortArticleAlpha: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
    sortArticleRating: function () {
      this.iCurrentPage = 1;
      this.getArticles();
    },
  },
};
</script>

<style scoped>
.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
}

.disponibility-article {
  background-color: #f50017;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  width: 40%;
  padding: 8px;
}

.disponibility-article p {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif {
  text-align: right;
  font-size: 12px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calif-num {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-article-name {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-article-address {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.button-view {
  width: 100%;
  height: 35px !important;
  background: transparent linear-gradient(260deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.text-article-price {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calification-number {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #ffc92a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-calification-texts {
  text-align: left;
  font-size: 10px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.card-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  position: relative;
}

.text-address {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.text-phone {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 5px;
}

.button-view-detail {
  background-color: #ffffff !important;
  border: 1px solid #283c4d;
  border-radius: 13px;
  opacity: 1;
  height: 45px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  text-transform: initial;
}

.text-link {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0071ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-underline:hover {
  text-decoration: underline;
}

.scroll-horizontal {
  width: 100%;
  overflow: auto;
}

.content-image {
  width: 100%;
  height: 250px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  .content-image img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 10px;
  }
}

.text-title {
  text-align: left;
  font-size: 21px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
}
</style>