const texts = {
  en: {
    article: {
      messageEdit: 'The item has been successfully modified.',
      lable1: 'Main characteristics of the item',
      lable2: 'Item location and classification',
      label3: 'Identification and packaging',
      label4: 'Required temperature',
      label5: 'Date and time the item will be available for pickup.',
      label6: 'Inventory and sale',
      txtLabelHoraEstimada: 'Estimated time(hh:mm)',
      titleScreen: 'New item creation',
      titleScreenEdit: 'Modify item creation',
      isVerifiedText: 'Your company has not been verified, contact PROPLAT.',
      withoutWarehouse: 'Before creating a item, you must first sign up a warehouse.',
      play: 'Available',
      pause: 'Paused',
      empty: 'There are no registered items.',
      filter: {
        textLabelCalification: 'Calification',
        textLabelAlphabetically: 'Alphabetically',
        textLabelPrice: 'Price',
        textLabelItemStatus: 'Item status',
        textLabelMinMax: 'Lowest to highest',
        textLabelMaxMin: 'Highest to lowest',
        textLabelDisponible: 'Available',
        textLabelSlow: 'Slow'
      },
      textArticle: 'My items',
      search: 'search by item name.',
      textCatalog: 'Category',
      textSubcatalog: 'Subcategory',
      textButtonAdd: 'Add item',
      textCalifications: 'Califications',
      textBox: 'Box',
      textButtonView: 'View item',
      dialogEdit: {
        textTitle: 'Item creation',
        textArticleMinAmount: 'Minimum of boxes for sale',
        textArticleMeasureUnit: 'Packaging type',
        textArticleNameEs: 'Item name in spanish',
        textArticleNameEn: 'Item name in english',
        textArticleCategoryType: 'Category type',
        textArticleCategory: 'Category',
        textArticleSubcategory: 'Subcategory',
        textArticleLotNumber: 'Lot number',
        textArticleDescriptionEs: 'Item description in spanish',
        textArticleDescriptionEn: 'Item description in english',
        textArticleBoxQuantity: 'Approximate items quantity ',
        textArticleBoxQuantityPlaceholder: 'Enter approximate items in inventory',
        textArticlePrice: 'Box price',
        textArticleWarehouse: 'Warehouse where the item is located',
        textArticleDisponibility: 'Estimated date(mm/dd/yyyy)',
        textArticlePreparationTime: 'Preparation time (hours)',
        textArticleTemperatureWarehouse: 'Storage temperature  (°F)',
        textArticleTemperatureTraslado: 'Transportation temperature (°F)',
        textArticleBoxPallet: 'Boxes per pallet',
        textPhographyTitle: 'Item photos',
        textPhographySubtitle: 'To move forward, upload at least one image.',
        textPhographyAddImage: 'Click to add an image',
        textBoxesPerPallet: 'Boxes per pallet',
        textPiecesPerBox: 'Pieces per pallet',
        textWeightPerBox: 'Weight per box (Lbs)',
        textButtonSave: 'Save',
        textButtonCancel: 'Cancel',
        textLabelImage: 'click or drag <br/> the image to add'
      }
    }
  },
  sp: {
    article: {
      messageEdit: 'El artículo ha sido modificado con éxito.',
      lable1: 'Características principales del artículo',
      lable2: 'Ubicación y clasificación del artículo',
      label3: 'Identificación y empacado',
      label4: 'Temperaturas requeridas',
      label5: 'Fecha y hora en que el artículo estará disponible para su recolección.',
      label6: 'Inventario y venta',
      txtLabelHoraEstimada: 'Hora estimada(hh:mm)',
      titleScreen: 'Creación de artículo nuevo',
      titleScreenEdit: 'Modificación de artículo nuevo',
      isVerifiedText: 'Tu empresa no ha sido verificada, contacta a PROPLAT.',
      withoutWarehouse: 'Antes de crear un artículo, primero debe de registrar un almacén.',
      play: 'Disponible',
      pause: 'Pausado',
      empty: 'No hay artículos registrados.',
      filter: {
        textLabelCalification: 'Calificación',
        textLabelAlphabetically: 'Alfabéticamente',
        textLabelPrice: 'Precio',
        textLabelItemStatus: 'Estado del artículo',
        textLabelMinMax: 'De menor a mayor',
        textLabelMaxMin: 'De mayor a menor',
        textLabelDisponible: 'Disponible',
        textLabelSlow: 'Pausado'
      },
      textArticle: 'Mis artículos',
      search: 'Buscar por nombre de artículo.',
      textCatalog: 'Categoría',
      textSubcatalog: 'Subcategoría',
      textButtonAdd: 'Añadir artículo',
      textCalifications: 'Calificaciones',
      textBox: 'Caja',
      textButtonView: 'Ver artículo',
      dialogEdit: {
        textTitle: 'Agregar nuevo artículo',
        textArticleMinAmount: 'Mínimo de cajas a la venta',
        textArticleMeasureUnit: 'Tipo de empaque',
        textArticleNameEs: 'Nombre del artículo en español',
        textArticleNameEn: 'Nombre del artículo en inglés',
        textArticleCategoryType: 'Tipo de categoría',
        textArticleCategory: 'Categoría',
        textArticleSubcategory: 'Subcategoría',
        textArticleLotNumber: 'Número de lote',
        textArticleDescriptionEs: 'Descripción del artículo en español',
        textArticleDescriptionEn: 'Descripción del artículo en inglés',
        textArticleBoxQuantity: 'Cantidad aproximada de artículos en inventario',
        textArticleBoxQuantityPlaceholder: 'Ingrese aproximada de artículos en inventario',
        textArticlePrice: 'Precio de caja',
        textArticleWarehouse: 'Almacén donde se encuentra el artículo',
        textArticleDisponibility: 'Fecha estimada(mm/dd/aaaa)',
        textArticlePreparationTime: 'Tiempo de preparación (horas)',
        textArticleTemperatureWarehouse: 'Temperatura del almacén (°F)',
        textArticleTemperatureTraslado: 'Temperatura de traslado (°F)',
        textArticleBoxPallet: 'Cajas por pallet',
        textPhographyTitle: 'Fotografías del artículo',
        textPhographySubtitle: 'Para avanzar, subir por lo menos una imagen.',
        textPhographyAddImage: 'Da click para añadir una imagen',
        textBoxesPerPallet: 'Cajas por pallet',
        textPiecesPerBox: 'Piezas por pallet',
        textWeightPerBox: 'Peso por caja (Lbs)',
        textButtonSave: 'Guardar',
        textButtonCancel: 'Cancelar',
        textLabelImage: 'dar click o arrastrar <br/> la imagen para añadir'
      }
    }
  }
};

export default texts;