var texts = {
  en: {
    orders: {
      factura: 'Invoice',
      comprobante: 'Proof of payment',
      descargar: 'Download file',
      empty: 'There are no registered purchase orders.',
      titleDelete: 'file',
      textDay: 'Day(s)',
      addDFile: 'Add file',
      updateState: {
        textTitle: 'Status update',
        textDisponibleState: {
          label: 'Available states',
          placeholder: 'Select state',
        },
        textComments: 'Comments',
        textLabelImage: 'Click to <br/> add image',
        textButtonCancel: 'Cancel',
        textButtonUpdate: 'Update status',
        textResponseLimitImages: 'Only a maximum of 15 images can be selected.'
      },
      buttonUpdate: 'Update',
      buttonConfirm: 'Confirm',
      buttonReject: 'Reject',
      orderTab: {
        textTitle: 'Orders in process',
        paymentMethod:"Payment method",
        buttonReturn: 'Back',
        textCreation: 'Creation',
        textDateLimit: 'Estimated border delivery',
        textDateFinished: 'Finalizated date',
        textState: 'Status',
        textPay: 'Payment',
        textTotalOrder: 'Order total',
        textVencFact: 'Invoice expiration',
        setcionOrder: {
          textTitle: 'Order',
          textSubtotal: 'Subtotal'
        },
        sectionFiles: {
          textTitle1: 'Required files',
          textTitle2: 'File history',
          headerRequiredFiles: {
            text1: 'Name',
            text2: 'File',
            text3: 'Actions'
          },
          headerHistorialFiles: {
            text1: 'Name',
            text2: 'Date',
            text3: 'Status',
            text4: 'Actions'
          },
        },
        sectionArticle: {
          text1: 'Item',
          text2: 'requested',
          text3: 'Price per box requested',
          text4: 'Final subtotal',
        },
        sectionWarehouse: {
          textTitle: 'Warehouse',
          textTitleSend: 'Shipping warehouse',
          textTitleEnt: 'Delivery warehouse',
        },
        sectionPaymentMethod: {
          textTitle: 'Paymenth method',
        },
        sectionCostSend: {
          textTitle: 'Shipping Cost',
          textCostFlete: 'Freight cost (Mexico)',
        },
        sectionFile: {
          textTitle: 'Optional files',
          textComprobant: 'Proof of payment',
          textFacture: 'invoice',
          textAddFile: 'Click to add file',
        },
        sectionFollow: {
          textTitle: 'Seguimiento',
          text1: 'Order received',
          text2: 'In preparation',
          text3: 'In transit',
          text4: 'In the process of border crossing',
          text5: 'Successful crossover',
          text6: 'Warehouse border',
          text7: 'Accepted at border warehouse',
          text8: 'Transferred',
          text9: 'On delivery route',
          text10: 'Delivered',
          text11: 'Finalized'
        }
      },
      textSearch: 'Search',
      textTitle: 'Purchase orders',
      textTab1: 'In process',
      textTab2: 'Interrupted',
      textTab3: 'Finalized',
      tableProcess: {
        headers: ['Purchase order', 'Buyer', 'Total', 'Creation date', 'Estimated border delivery', 'Payment status', 'Status', 'Actions']
      },
      tableInterrupted: {
        headers: ['Purchase order', 'Buyer', 'Total', 'Dispute creation date', 'Status', 'Actions']
      },
      tableFinished: {
        headers: ['Purchase order', 'Buyer', 'Total', 'End date', 'Status', 'Payment states', 'Payment receipt', 'Actions']
      },
      filters: {
        textSearch: 'Search by purchase order or buyer.',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
        // paymentStatus: 'Payment status',
        textLabelStatus: 'Order status',
        textLabelStatusPaid: 'Payment status',
        paymentStatusPaid: 'Paid',
        paymentStatusToPaid: 'To pay',
        orderStatusFinalizado: [
          {
            value: "18b984e7-2295-40d8-aaa1-8f4f89fdaed8",
            name: "Finalized",
          },
          {
            value: "38b51cdd-95ca-4870-acde-68ed35597adb",
            name: "Cancelled",
          },
        ],
        orderStatus: [
          {
            value: "dac89b64-079a-4c71-b894-49436e0b61d5",
            name: "To be confirmed",
          },
          {
            value: "37a8cdd3-ef55-4bd5-a310-9bea096ffc15",
            name: "In preparation",
          },
          {
            value: "6639b3de-c4d0-4db3-8788-091082e5a7c3",
            name: "Packed",
          },
          {
            value: "19f0f0a8-0d25-4bdb-ab3a-3a6a3272504a",
            name: "Collected",
          },
          {
            value: "5c704c1c-4a57-42be-9297-9db12d5399bb",
            name: "In transit",
          },
          {
            value: "e39445ed-0157-40c5-9eec-3b315df9fc64",
            name: "In the process of border crossing",
          },
          {
            value: "81213a44-3cba-4a9c-b6cd-add0daaebb60",
            name: "Successful crossing",
          },
          {
            value: "e52f3f99-eff0-440e-83e4-ee892e7a219a",
            name: "Border warehouse",
          },
          {
            value: "88d33a8d-763c-4618-82a7-a30f9bc63f22",
            name: "Accepted at border warehouse",
          },
          {
            value: "95fdd142-1d38-43c3-bcbc-d7b2b240c8a5",
            name: "Transferred",
          },
          {
            value: "5896d124-07d1-45ce-8189-c4299e43b746",
            name: "On delivery route",
          },
          {
            value: "b0d3be8a-39b4-4ba1-8654-86c14ae9e1e2",
            name: "Delivered",
          },
        ]
      }
    },

  },
  sp: {
    orders: {
      factura: 'Factura',
      comprobante: 'Comprobante de pago',
      descargar: 'Descargar archivo',
      empty: 'No hay órdenes de compra registradas.',
      titleDelete: 'archivo',
      textDay: 'Día(s)',
      addDFile: 'Añadir archivo',
      updateState: {
        textTitle: 'Actualización de estado',
        textDisponibleState: {
          label: 'Estados disponibles',
          placeholder: 'Seleccionar estado',
        },
        textComments: 'Comentarios',
        textLabelImage: 'Da click para <br/> añadir imagen',
        textButtonCancel: 'Cancelar',
        textButtonUpdate: 'Actualizar estado',
        textResponseLimitImages: 'Solo se pueden seleccionar como maximo 15 imagenes.'
      },
      buttonUpdate: 'Actualizar',
      buttonConfirm: 'Confirmar',
      buttonReject: 'Rechazar',
      orderTab: {
        textTitle: 'Órdenes en proceso',
        paymentMethod:"Método de pago",
        buttonReturn: 'Regresar',
        textCreation: 'Creación',
        textDateLimit: 'Fecha estimada de entrega en frontera',
        textDateFinished: 'Fecha de finalización',
        textState: 'Estado',
        textPay: 'Pago',
        textTotalOrder: 'Total de la orden',
        textVencFact: 'Vencimiento de factura',
        setcionOrder: {
          textTitle: 'Pedido',
          textSubtotal: 'Subtotal'
        },
        sectionFiles: {
          textTitle1: 'Archivos requeridos',
          textTitle2: 'Historial de archivos',
          headerRequiredFiles: {
            text1: 'Nombre',
            text2: 'Archivo',
            text3: 'Acciones'
          },
          headerHistorialFiles: {
            text1: 'Nombre',
            text2: 'Fecha',
            text3: 'Estado',
            text4: 'Acciones'
          },
        },
        sectionArticle: {
          text1: 'Artículo',
          text2: 'solicitados(as)',
          text3: 'Precio por caja solicitada',
          text4: 'Subtotal final',
        },
        sectionWarehouse: {
          textTitle: 'Almacén',
          textTitleSend: 'Almacén de envío',
          textTitleEnt: 'Almacén de entrega',
        },
        sectionPaymentMethod: {
          textTitle: 'Método de pago',
        },
        sectionCostSend: {
          textTitle: 'Costo de envío',
          textCostFlete: 'Costo de flete (México)',
        },
        sectionFile: {
          textTitle: 'Archivos opcionales',
          textComprobant: 'Comprobante de pago',
          textFacture: 'Factura',
          textAddFile: 'Da click para añadir archivo',
        },
        sectionFollow: {
          textTitle: 'Seguimiento',
          text1: 'Pedido recibido',
          text2: 'En preparación',
          text3: 'En tránsito',
          text4: 'En proceso de cruce fronterizo',
          text5: 'Cruce exitoso',
          text6: 'Almacén frontera',
          text7: 'Aceptado en almacén frontera',
          text8: 'Transferido',
          text9: 'En ruta de entrega',
          text10: 'Entregado',
          text11: 'Finalizado'
        }
      },
      textSearch: 'Buscar',
      textTitle: 'Órdenes de compra',
      textTab1: 'En proceso',
      textTab2: 'Interrumpidos',
      textTab3: 'Finalizados',
      tableProcess: {
        headers: ['Orden de compra', 'Comprador', 'Total', 'Fecha de creación', 'Fecha estimada de entrega en frontera', 'Estado de pago', 'Estado', 'Acciones']
      },
      tableInterrupted: {
        headers: ['Orden de compra', 'Comprador', 'Total', 'Fecha de creación de disputa', 'Estado', 'Acciones']
      },
      tableFinished: {
        headers: ['Orden de compra', 'Comprador', 'Total', 'Fecha de finalización', 'Estado', 'Estado de pago', 'Comprobante de pago', 'Acciones']
      },
      filters: {
        textSearch: 'Buscar por orden de compra o comprador.',
        startDate: 'Fecha inicio',
        endDate: 'Fecha final',
        status: 'Estado',
        // paymentStatus: 'Estado de pago',
        textLabelStatus: 'Estado de pedido',
        textLabelStatusPaid: 'Estado de pago',
        paymentStatusPaid: 'Pagado',
        paymentStatusToPaid: 'Por pagar',
        orderStatusFinalizado: [
          {
            value: "18b984e7-2295-40d8-aaa1-8f4f89fdaed8",
            name: "Finalizado",
          },
          {
            value: "38b51cdd-95ca-4870-acde-68ed35597adb",
            name: "Cancelado",
          },
        ],
        orderStatus: [
          {
            value: "dac89b64-079a-4c71-b894-49436e0b61d5",
            name: "Por confirmar",
          },
          {
            value: "37a8cdd3-ef55-4bd5-a310-9bea096ffc15",
            name: "En preparación",
          },
          {
            value: "6639b3de-c4d0-4db3-8788-091082e5a7c3",
            name: "Empacado",
          },
          {
            value: "19f0f0a8-0d25-4bdb-ab3a-3a6a3272504a",
            name: "Recolectado",
          },
          {
            value: "5c704c1c-4a57-42be-9297-9db12d5399bb",
            name: "En tránsito",
          },
          {
            value: "e39445ed-0157-40c5-9eec-3b315df9fc64",
            name: "En proceso de cruce fronterizo",
          },
          {
            value: "81213a44-3cba-4a9c-b6cd-add0daaebb60",
            name: "Cruce exitoso",
          },
          {
            value: "e52f3f99-eff0-440e-83e4-ee892e7a219a",
            name: "Almacén frontera",
          },
          {
            value: "88d33a8d-763c-4618-82a7-a30f9bc63f22",
            name: "Aceptado en almacén frontera",
          },
          {
            value: "95fdd142-1d38-43c3-bcbc-d7b2b240c8a5",
            name: "Transferido",
          },
          {
            value: "5896d124-07d1-45ce-8189-c4299e43b746",
            name: "En ruta de entrega",
          },
          {
            value: "b0d3be8a-39b4-4ba1-8654-86c14ae9e1e2",
            name: "Entregado",
          },
        ]
      }
    },

  }
};

export default texts;